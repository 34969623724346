import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadScript } from "../../../../../globals/constants";
import { fetchDashboard } from "../../../../../reducers/employer/EmpDashboardSlice";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EmpPostAJobPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const empAds = useSelector((state) => state.EmpDashboard.empAds);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);

  const [formData, setFormData] = useState({
    jobTitle: "",
    jobCategory: "",
    jobType: "",
    offeredSalary: "",
    experience: "",
    qualification: "",
    state: "",
    district: "",
    requirements: "",
    description: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form submitted:", formData);
    toast.success("Job has been posted!");
  };

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
  }, [dispatch]);

  return (
    <>
      <div className="wt-admin-right-page-header clearfix">
        <div className="row">
          <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
            <h2>Post a Job</h2>
            <div className="breadcrumbs">
              <a>Home</a>
              <a>Dashboard</a>
              <span>Post a new Job</span>
            </div>
          </div>
          <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
            <NavLink to={empAds?.PostJobs_Header_Link} target="_blank">
              <img
                src={empAds?.PostJobs_Header}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-heading wt-panel-heading p-a20">
          <h4 className="panel-tittle m-a0">
            <i className="fa fa-suitcase" />
            Job Details
          </h4>
        </div>
        <div className="panel-body wt-panel-body p-a20 m-b30 ">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Job Title</label>
                  <input
                    className="form-control"
                    name="jobTitle"
                    type="text"
                    placeholder="Pharmacist"
                    value={formData.jobTitle}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              {/*Job Category*/}
              <div className="col-xl-4 col-lg-6 col-md-12">
                <div className="form-group city-outer-bx has-feedback">
                  <label>Job Category</label>
                  <div className="ls-inputicon-box">
                    <select
                      className="wt-select-box selectpicker"
                      data-live-search="true"
                      title="Select Category"
                      id="JobCategory"
                      data-bv-field="size"
                      name="jobCategory"
                      value={formData.jobCategory}
                      onChange={handleInputChange}
                      required
                    >
                      <option disabled value="">
                        Select Category
                      </option>
                      <option>ENT</option>
                      <option>Anaesthesia</option>
                      <option>Anatomy</option>
                      {/* Other options */}
                    </select>
                    <i className="fs-input-icon fa fa-border-all" />
                  </div>
                </div>
              </div>
              {/*Job Type*/}
              <div className="col-xl-4 col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Job Type</label>
                  <div className="ls-inputicon-box">
                    <select
                      className="wt-select-box selectpicker"
                      data-live-search="true"
                      title="Select Category"
                      id="JobType"
                      data-bv-field="size"
                      name="jobType"
                      value={formData.jobType}
                      onChange={handleInputChange}
                      required
                    >
                      <option className="bs-title-option" value>
                        Select Category
                      </option>
                      <option>Full Time</option>
                      <option>Part Time</option>
                      <option>Intern</option>
                    </select>
                    <i className="fs-input-icon fa fa-file-alt" />
                  </div>
                </div>
              </div>
              {/*Offered Salary*/}
              <div className="col-xl-4 col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Offered Salary</label>
                  <div className="ls-inputicon-box">
                    <input
                      className="form-control"
                      id="OfferedSalary"
                      name="offeredSalary"
                      type="text"
                      placeholder="10,000 - 20,000"
                      value={formData.offeredSalary}
                      onChange={handleInputChange}
                      required
                    />
                    <i className="fs-input-icon fa fa-rupee-sign" />
                  </div>
                </div>
              </div>
              {/*Experience*/}
              <div className="col-xl-4 col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Experience</label>
                  <div className="ls-inputicon-box">
                    <input
                      className="form-control"
                      id="Experience"
                      name="experience"
                      type="text"
                      placeholder="E.g. Minimum 3 years"
                      value={formData.experience}
                      onChange={handleInputChange}
                      required
                    />
                    <i className="fs-input-icon fa fa-user-edit" />
                  </div>
                </div>
              </div>
              {/*Qualification*/}
              <div className="col-xl-4 col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Qualification</label>
                  <div className="ls-inputicon-box">
                    <input
                      className="form-control"
                      id="Qualification"
                      name="qualification"
                      type="text"
                      placeholder="Qualification Title"
                      value={formData.qualification}
                      onChange={handleInputChange}
                      required
                    />
                    <i className="fs-input-icon fa fa-user-graduate" />
                  </div>
                </div>
              </div>
              {/*Country*/}
              <div className="col-xl-4 col-lg-6 col-md-12">
                <div className="form-group">
                  <label>State</label>
                  <div className="ls-inputicon-box">
                    <select
                      className="wt-select-box selectpicker"
                      data-live-search="true"
                      title="Karnataka"
                      id="state"
                      data-bv-field="size"
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}
                      required
                      disabled
                    >
                      <option>Karnataka</option>
                    </select>
                    <i className="fs-input-icon fa fa-globe-americas" />
                  </div>
                </div>
              </div>
              {/*City*/}
              <div className="col-xl-4 col-lg-6 col-md-12">
                <div className="form-group">
                  <label>District</label>
                  <div className="ls-inputicon-box">
                    <select
                      className="wt-select-box selectpicker"
                      data-live-search="true"
                      title="City"
                      id="District"
                      data-bv-field="size"
                      name="district"
                      value={formData.district}
                      onChange={handleInputChange}
                      required
                    >
                      <option className="bs-title-option" value>
                        City
                      </option>
                      <option>Bagalkote</option>
                      <option>Ballari</option>
                      <option>Belagavi</option>
                      <option>Bengaluru Rural</option>
                      <option>Bengaluru Urban</option>
                      <option>Bidar</option>
                      <option>Chamarajanagara</option>
                      <option>Chikkaballapura</option>
                      <option>Chikkamagaluru</option>
                      <option>Chitradurga</option>
                      <option>Dakshina Kannada</option>
                      <option>Davangere</option>
                      <option>Dharwad</option>
                      <option>Gadag</option>
                      <option>Hassan</option>
                      <option>Haveri</option>
                      <option>Kalaburagi</option>
                      <option>Kodagu</option>
                      <option>Kolar</option>
                      <option>Koppal</option>
                      <option>Mandya</option>
                      <option>Mysuru</option>
                      <option>Raichur</option>
                      <option>Ramanagara</option>
                      <option>Shivamogga</option>
                      <option>Tumakuru</option>
                      <option>Udupi</option>
                      <option>Uttara Kannada</option>
                      <option>Vijayanagar</option>
                      <option>Vijayapura</option>
                      <option>Yadgir</option>
                    </select>
                    <i className="fs-input-icon fa fa-map-marker-alt" />
                  </div>
                </div>
              </div>
              {/* Requirements*/}
              <div className="col-xl-4 col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Requirements</label>
                  <div className="ls-inputicon-box">
                    <input
                      className="form-control"
                      id="Requirements"
                      name="requirements"
                      type="text"
                      placeholder="Type Requirements"
                      value={formData.requirements}
                      onChange={handleInputChange}
                      required
                    />
                    <i className="fs-input-icon fa fa-file-alt" />
                  </div>
                </div>
              </div>
              {/*Description*/}
              <div className="col-md-12">
                <div className="form-group">
                  <label>Description</label>
                  <textarea
                    className="form-control"
                    rows={3}
                    id="description"
                    name="description"
                    placeholder="Greetings! We are Apollo Hospitals. We hope you enjoy our services and quality."
                    value={formData.description}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="text-left">
                  <button type="submit" className="site-button m-r5">
                    Publish Job
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer /> {/* Toast container */}
      <div className="col-xl-12 col-lg-12 col-md-12 mb-4">
        <NavLink to={empAds?.PostJobs_Footer_Link} target="_blank">
          <img
            src={empAds?.PostJobs_Footer}
            alt="Banner Image"
            className="img-fluid"
          />
        </NavLink>
      </div>
    </>
  );
}
export default EmpPostAJobPage;
