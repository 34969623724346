import { createSlice } from "@reduxjs/toolkit";

//Initial Value of Slice 
const initialState = {
    List: [
        {
            Id: "Primary-1",
            Title: "Bhanavi Hospital",
            Address: "Bangalore",
            Designation: "Physician assistant",
            Date: "15/06/2023",
            Time: "10:35 am",
            ImgUrl : "https://c2m-edutech.com/demo/photo1_150.jpg"
        },
        {
            Id: "Primary-2",
            Title: "Apollo Hospitals Bannerghatta",
            Address: "Bangalore",
            Designation: "Registered nurse. ",
            Date: "25/06/2023",
            Time: "12:35 pm",
            ImgUrl : "https://c2m-edutech.com/demo/photo2_150.jpg"
        }
    ]
}

//Action of Slice
const CanSearchJobSlice = createSlice({
    name: "CanSearchJob",
    initialState,
    reducers: {}
})

//Export of Slice
export const { } = CanSearchJobSlice.actions;
export default CanSearchJobSlice.reducer;