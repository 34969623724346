import { useEffect } from "react";
import CountUp from "react-countup";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadScript } from "../../../../globals/constants";
import { fetchDashboard } from "../../../../reducers/employer/EmpDashboardSlice";

function EmpDashboardPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const empAds = useSelector((state) => state.EmpDashboard.empAds);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
  }, [dispatch]);

  return (
    <>
      <div className="wt-admin-right-page-header clearfix">
        <div className="row">
          <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
            <h2>Dashboard</h2>
            <div className="breadcrumbs">
              <a>Home</a>
              <a>Dashboard</a>
              <span>Deals</span>
            </div>
          </div>
          <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
            <NavLink to={empAds?.Dashboard_Header_Link} target="_blank">
              <img
                src={empAds?.Dashboard_Header}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </div>
      </div>
      <div className="twm-dash-b-blocks mb-5">
        <div className="row">
          <div className="col-xl-3 col-lg-6 col-md-12 mb-3">
            <div className="panel panel-default">
              <div className="panel-body wt-panel-body gradi-1 dashboard-card ">
                <div className="wt-card-wrap">
                  <div className="wt-card-icon">
                    <i className="far fa-address-book" />
                  </div>
                  <div className="wt-card-right wt-total-active-listing counter ">
                    <CountUp end={25} duration={10} />
                  </div>
                  <div className="wt-card-bottom ">
                    <h4 className="m-b0">Posted Jobs</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-md-12 mb-3">
            <div className="panel panel-default">
              <div className="panel-body wt-panel-body gradi-2 dashboard-card ">
                <div className="wt-card-wrap">
                  <div className="wt-card-icon">
                    <i className="far fa-file-alt" />
                  </div>
                  <div className="wt-card-right  wt-total-listing-view counter ">
                    <CountUp end={435} duration={10} />
                  </div>
                  <div className="wt-card-bottom">
                    <h4 className="m-b0">Total Applications</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-md-12 mb-3">
            <div className="panel panel-default">
              <div className="panel-body wt-panel-body gradi-3 dashboard-card ">
                <div className="wt-card-wrap">
                  <div className="wt-card-icon">
                    <i className="far fa-envelope" />
                  </div>
                  <div className="wt-card-right wt-total-listing-review counter ">
                    <CountUp end={28} duration={10} />
                  </div>
                  <div className="wt-card-bottom">
                    <h4 className="m-b0">Messages</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-md-12 mb-3">
            <div className="panel panel-default">
              <div className="panel-body wt-panel-body gradi-4 dashboard-card ">
                <div className="wt-card-wrap">
                  <div className="wt-card-icon">
                    <i className="far fa-bell" />
                  </div>
                  <div className="wt-card-right wt-total-listing-bookmarked counter ">
                    <CountUp end={18} duration={10} />
                  </div>
                  <div className="wt-card-bottom">
                    <h4 className="m-b0">Notifications</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="twm-pro-view-chart-wrap">
        <div className="row">
          <div className="col-lg-12 col-md-12 mb-4">
            <div className="panel panel-default">
              <div className="panel-heading wt-panel-heading p-a20">
                <h4 className="panel-tittle m-a0">Recent Applicants</h4>
              </div>
              <div className="panel-body wt-panel-body bg-white">
                <div className="twm-dashboard-candidates-wrap">
                  <div className="row">
                    <div className="col-xl-6 col-lg-12 col-md-12">
                      <div className="twm-dash-candidates-list">
                        <div className="twm-media">
                          <div className="twm-media-pic">
                            <img
                              src="https://c2m-edutech.com/demo/person1.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="twm-mid-content">
                          <a href="#" className="twm-job-title">
                            <h4>Sanjay B</h4>
                          </a>
                          <p>Charted Accountant</p>
                          <div className="twm-fot-content">
                            <div className="twm-left-info">
                              <p className="twm-candidate-address">
                                <i className="feather-map-pin" />
                                New York
                              </p>
                              <div className="twm-jobs-vacancies">
                                ₹2000<span>/ Day</span>
                              </div>
                            </div>
                            <div className="twm-right-btn">
                              <ul className="twm-controls-icon list-unstyled">
                                <li>
                                  <button
                                    title="View profile"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="fa fa-eye" />
                                  </button>
                                </li>
                                <li>
                                  <button
                                    title="Send message"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="far fa-envelope-open" />
                                  </button>
                                </li>
                                <li>
                                  <button
                                    title="Delete"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="far fa-trash-alt" />
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-12">
                      <div className="twm-dash-candidates-list">
                        <div className="twm-media">
                          <div className="twm-media-pic">
                            <img
                              src="https://c2m-edutech.com/demo/person4.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="twm-mid-content">
                          <a href="#" className="twm-job-title">
                            <h4>Sahana N</h4>
                          </a>
                          <p>Medical Professed</p>
                          <div className="twm-fot-content">
                            <div className="twm-left-info">
                              <p className="twm-candidate-address">
                                <i className="feather-map-pin" />
                                New York
                              </p>
                              <div className="twm-jobs-vacancies">
                                ₹70<span>/ Hour</span>
                              </div>
                            </div>
                            <div className="twm-right-btn">
                              <ul className="twm-controls-icon list-unstyled">
                                <li>
                                  <button
                                    title="View profile"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="fa fa-eye" />
                                  </button>
                                </li>
                                <li>
                                  <button
                                    title="Send message"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="far fa-envelope-open" />
                                  </button>
                                </li>
                                <li>
                                  <button
                                    title="Delete"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="far fa-trash-alt" />
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-12">
                      <div className="twm-dash-candidates-list">
                        <div className="twm-media">
                          <div className="twm-media-pic">
                            <img
                              src="https://c2m-edutech.com/demo/person3.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="twm-mid-content">
                          <a href="#" className="twm-job-title">
                            <h4>Sooraj R</h4>
                          </a>
                          <p>Bank Manger</p>
                          <div className="twm-fot-content">
                            <div className="twm-left-info">
                              <p className="twm-candidate-address">
                                <i className="feather-map-pin" />
                                New York
                              </p>
                              <div className="twm-jobs-vacancies">
                                ₹1800<span>/ Day</span>
                              </div>
                            </div>
                            <div className="twm-right-btn">
                              <ul className="twm-controls-icon list-unstyled">
                                <li>
                                  <button
                                    title="View profile"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="fa fa-eye" />
                                  </button>
                                </li>
                                <li>
                                  <button
                                    title="Send message"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="far fa-envelope-open" />
                                  </button>
                                </li>
                                <li>
                                  <button
                                    title="Delete"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="far fa-trash-alt" />
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-12">
                      <div className="twm-dash-candidates-list">
                        <div className="twm-media">
                          <div className="twm-media-pic">
                            <img
                              src="https://c2m-edutech.com/demo/person1.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="twm-mid-content">
                          <a href="#" className="twm-job-title">
                            <h4>Vinay S</h4>
                          </a>
                          <p>IT Contractor</p>
                          <div className="twm-fot-content">
                            <div className="twm-left-info">
                              <p className="twm-candidate-address">
                                <i className="feather-map-pin" />
                                New York
                              </p>
                              <div className="twm-jobs-vacancies">
                                ₹9000<span>/ Week</span>
                              </div>
                            </div>
                            <div className="twm-right-btn">
                              <ul className="twm-controls-icon list-unstyled">
                                <li>
                                  <button
                                    title="View profile"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="fa fa-eye" />
                                  </button>
                                </li>
                                <li>
                                  <button
                                    title="Send message"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="far fa-envelope-open" />
                                  </button>
                                </li>
                                <li>
                                  <button
                                    title="Delete"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="far fa-trash-alt" />
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-12">
                      <div className="twm-dash-candidates-list">
                        <div className="twm-media">
                          <div className="twm-media-pic">
                            <img
                              src="https://c2m-edutech.com/demo/person4.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="twm-mid-content">
                          <a href="#" className="twm-job-title">
                            <h4>Roshini S </h4>
                          </a>
                          <p>Charity &amp; Voluntary</p>
                          <div className="twm-fot-content">
                            <div className="twm-left-info">
                              <p className="twm-candidate-address">
                                <i className="feather-map-pin" />
                                New York
                              </p>
                              <div className="twm-jobs-vacancies">
                                ₹190<span>/ Hour</span>
                              </div>
                            </div>
                            <div className="twm-right-btn">
                              <ul className="twm-controls-icon list-unstyled">
                                <li>
                                  <button
                                    title="View profile"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="fa fa-eye" />
                                  </button>
                                </li>
                                <li>
                                  <button
                                    title="Send message"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="far fa-envelope-open" />
                                  </button>
                                </li>
                                <li>
                                  <button
                                    title="Delete"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="far fa-trash-alt" />
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-12 col-lg-12 col-md-12 mb-4">
        <NavLink to={empAds?.Dashboard_Footer_Link} target="_blank">
          <img
            src={empAds?.Dashboard_Footer}
            alt="Banner Image"
            className="img-fluid"
          />
        </NavLink>
      </div>
    </>
  );
}

export default EmpDashboardPage;
