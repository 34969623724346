import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadScript } from "../../../../globals/constants";
import { fetchDashboard } from "../../../../reducers/employer/EmpDashboardSlice";

function Empcontact(props) {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const empAds = useSelector((state) => state.EmpDashboard.empAds);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
  }, [dispatch, loading, userUUID]);

  return (
    <>
      <div className="wt-admin-right-page-header clearfix">
        <div className="row">
          <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
            <h2>Contact</h2>
            <div className="breadcrumbs">
              <NavLink to="/">Home</NavLink>
              <NavLink to="/dashboard">Dashboard</NavLink>
              <span>Contact</span>
            </div>
          </div>
          <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
            <NavLink to={empAds?.Dashboard_Header_Link} target="_blank">
              <img
                src={empAds?.Dashboard_Header}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </div>
      </div>

      <div className="col-xl-12 col-lg-12 col-md-12 mb-4">
        <NavLink to={empAds?.Dashboard_Footer_Link} target="_blank">
          <img
            src={empAds?.Dashboard_Footer}
            alt="Banner Image"
            className="img-fluid"
          />
        </NavLink>
      </div>

      <div className="contact-details">
        <div
          style={{
            border: "1px solid #ccc",
            borderRadius: "8px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            padding: "20px",
            marginBottom: "20px",
          }}
        >
          <h3
            style={{
              fontSize: "1.5rem",
              marginBottom: "10px",
            }}
          >
            Contact Information
          </h3>
          <p style={{ color: "#555", lineHeight: "1.6" }}>
            <strong>Company Name:</strong> Example Company <br />
            <strong>Address:</strong> 1234 Example Street, Example City, EX
            12345
          </p>
          <ul className="list-group" style={{ listStyle: "none", padding: 0 }}>
            <li
              className="list-group-item"
              style={{ border: "none", padding: "8px 0" }}
            >
              <strong>Phone:</strong> (123) 456-7890
            </li>
            <li
              className="list-group-item"
              style={{ border: "none", padding: "8px 0" }}
            >
              <strong>Email:</strong> contact@example.com
            </li>
            <li
              className="list-group-item"
              style={{ border: "none", padding: "8px 0" }}
            >
              <strong>Business Hours:</strong>{" "}
              <span style={{ fontStyle: "italic", color: "#888" }}>
                Monday - Friday, 9:00 AM - 5:00 PM
              </span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Empcontact;
