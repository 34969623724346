import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadScript } from "../../../../globals/constants";
import { fetchDashboard } from "../../../../reducers/employer/EmpDashboardSlice";
import SectionPricingAnnual from "../../public-user/sections/pricing/section-pricing-annual";
import SectionPricingMonthly from "../../public-user/sections/pricing/section-pricing-monthly";

function EmpPricingPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const empAds = useSelector((state) => state.EmpDashboard.empAds);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
  }, [dispatch]);

  return (
    <>
      <div className="wt-admin-right-page-header clearfix">
        <div className="row">
          <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
            <h2>Pricing</h2>
            <div className="breadcrumbs">
              <a>Home</a>
              <a>Dashboard</a>
              <span>Pricing Details</span>
            </div>
          </div>
          <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
            <NavLink to={empAds?.Pricing_Header_Link} target="_blank">
              <img
                src={empAds?.Pricing_Header}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </div>
      </div>
      <div className="section-content">
        <div className="twm-tabs-style-1">
          {/* <ul className="nav nav-tabs" id="myTab3" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="Monthly"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
              >
                Monthly
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="annual"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
              >
                Annual
              </button>
            </li>
          </ul> */}
          <div className="tab-content" id="myTab3Content">
            <div
              className="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="Monthly"
            >
              <SectionPricingMonthly />
            </div>
            <div
              className="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="annual"
            >
              <SectionPricingAnnual />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 mb-4">
          <NavLink to={empAds?.Pricing_Footer_Link} target="_blank">
            <img
              src={empAds?.Pricing_Footer}
              alt="Banner Image"
              className="img-fluid"
            />
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default EmpPricingPage;
