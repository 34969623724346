import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Index from "../../candidate/common/no-data/Index";
import { loadScript } from "../../../../globals/constants";
import { fetchDashboard } from "../../../../reducers/employer/EmpDashboardSlice";
import { fetchEmpBookmark } from "../../../../reducers/employer/EmpBookmarkSlice";

function EmpBookmarksPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const empAds = useSelector((state) => state.EmpDashboard.empAds);
  const bookmarkResumeList = useSelector((state) => state.EmpBookmark);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);

  console.log(bookmarkResumeList);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
    dispatch(fetchEmpBookmark(userUUID));
  }, [dispatch]);

  return (
    <>
      {bookmarkResumeList.loading === true ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          <div className="wt-admin-right-page-header clearfix">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
                <h2>Bookmark</h2>
                <div className="breadcrumbs">
                  <a>Home</a>
                  <a>Dashboard</a>
                  <span>Bookmarked Resumes</span>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
                <NavLink to={empAds?.Bookmark_Header_Link} target="_blank">
                  <img
                    src={empAds?.Bookmark_Header}
                    alt="Banner Image"
                    className="img-fluid"
                  />
                </NavLink>
              </div>
            </div>
          </div>
          <div>
            {bookmarkResumeList?.EmpBookmark.length > 0 ? (
              <div className="twm-pro-view-chart-wrap">
                <div className="col-lg-12 col-md-12 mb-4">
                  <div className="panel panel-default site-bg-white m-t30">
                    <div className="panel-heading wt-panel-heading p-a20">
                      <h4 className="panel-tittle m-a0">
                        <i className="far fa-bookmark" />
                        Bookmarked Resumes
                      </h4>
                    </div>
                    <div className="panel-body wt-panel-body">
                      <div className="twm-D_table p-a20 table-responsive">
                        <table
                          id="jobs_bookmark_table"
                          className="table table-bordered twm-bookmark-list-wrap"
                        >
                          <thead>
                            <tr>
                              <th>Sl.No</th>
                              <th>Profiles</th>
                              <th>Availability</th>
                              <th>Enrolled Date</th>
                              <th>Job Category</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {bookmarkResumeList?.EmpBookmark?.map(
                              (item, index) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>
                                    <div className="twm-bookmark-list">
                                      <div className="twm-media">
                                        <div className="twm-media-pic">
                                          <img src={item.ImgUrl} alt="" />
                                        </div>
                                      </div>
                                      <div className="twm-mid-content">
                                        <a href="#" className="twm-job-title">
                                          <h4>{item.Title}</h4>
                                        </a>
                                        <p className="twm-bookmark-address">
                                          <i className="feather-map-pin" />
                                          {item.Address}
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="twm-job-post-duration">
                                      {item.Duration}
                                    </div>
                                  </td>
                                  <td>{item.Date}</td>
                                  <td>
                                    <div className="twm-jobs-category">
                                      <span className="twm-bg-brown">
                                        {item.Category}
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="twm-table-controls">
                                      <ul className="twm-DT-controls-icon list-unstyled">
                                        <li>
                                          <button
                                            title="View profile"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                          >
                                            <span className="fa fa-eye" />
                                          </button>
                                        </li>
                                        <li>
                                          <button
                                            title="Send message"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                          >
                                            <span className="far fa-envelope-open" />
                                          </button>
                                        </li>
                                        <li>
                                          <button
                                            title="Delete"
                                            data-bs-toggle="modal"
                                            data-bs-target="#logout-delete-profile"
                                          >
                                            <span className="far fa-trash-alt" />
                                          </button>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                          <tfoot>
                            <tr>
                              <th>Sl.No</th>
                              <th>Profiles</th>
                              <th>Availability</th>
                              <th>Enrolled Date</th>
                              <th>Job Category</th>
                              <th>Action</th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Index />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mt-4">
              <NavLink to={empAds?.Bookmark_Footer_Link} target="_blank">
                <img
                  src={empAds?.Bookmark_Footer}
                  alt="Banner Image"
                  className="img-fluid"
                />
              </NavLink>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default EmpBookmarksPage;
