import { createSlice } from "@reduxjs/toolkit";
import { getDocs, collection, query, where } from "firebase/firestore";
import { firestore } from "../../config/fbConfig";

// Initial Value of Slice
const initialState = {
  EmpTickets: [],
  loading: false,
  error: null,
};

// Action of Slice
const EmpTicketsSlice = createSlice({
  name: "EmpTickets",
  initialState,
  reducers: {
    setEmpTicketsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setEmpTicketsSuccess: (state, action) => {
      state.loading = false;
      state.EmpTickets = action.payload;
    },
    setEmpTicketsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// Export of Slice
export const {
  setEmpTicketsStart,
  setEmpTicketsSuccess,
  setEmpTicketsFailure,
} = EmpTicketsSlice.actions;
export default EmpTicketsSlice.reducer;

export const fetchEmpTickets = (userUUID) => async (dispatch) => {
  try {
    dispatch(setEmpTicketsStart());

    const q = query(
      collection(firestore, "Tickets"),
      where("ClientId", "==", userUUID)
    );

    const querySnapshot = await getDocs(q);

    const EmpTickets = [];
    querySnapshot.forEach((doc) => {
      EmpTickets.push(doc.data());
    });

    dispatch(setEmpTicketsSuccess(EmpTickets));
  } catch (error) {
    dispatch(setEmpTicketsFailure(error.message));
  }
};
