import { useEffect, useState, useMemo } from "react";
import { ThemeProvider, createTheme, Pagination, Stack } from "@mui/material";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Index from "../common/no-data/Index";
import CircularProgress from "../../../common/loading/Index";
import { loadScript } from "../../../../globals/constants";
import { publicUser } from "../../../../globals/route-names";
import { fetchDashboard } from "../../../../reducers/candidate/CanDashboardSlice";
import { fetchAppliedJobs } from "../../../../reducers/candidate/CanAppliedJobsSlice";
import SectionRecordsFilter from "../../public-user/sections/common/section-records-filter";

function CanAppliedJobsPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.CanDashboard.loading);
  const AppliedJobsList = useSelector((state) => state.CanAppliedJobs);
  const canAds = useSelector((state) => state.CanDashboard.canAds);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const appliedLoading = useSelector((state) => state.CanAppliedJobs.loading);
  const updatedItems = AppliedJobsList?.CanAppliedJobs?.map((item) => {
    const milliseconds = item.Date.seconds * 1000;
    const totalMilliseconds = milliseconds + item.Date.nanoseconds / 1000000;
    const date = new Date(totalMilliseconds);
    const newDate = moment(date).format("MMM Do YYYY");

    return {
      ...item,
      formattedDate: newDate,
    };
  });

  const itemsPerPage = 1;
  const totalPages = Math.ceil(updatedItems?.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, updatedItems?.length);
  const currentItems = updatedItems.slice(startIndex, endIndex);

  useMemo(() => {
    window.scrollTo({ top: 0 });
  }, [currentPage]);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchAppliedJobs(userUUID));
  }, [dispatch]);

  const _filterConfig = {
    prefix: "Applied",
    type: "jobs",
    total: "250",
    showRange: false,
    showingUpto: "",
  };

  return (
    <>
      {appliedLoading === true ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="wt-admin-right-page-header clearfix">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
                <h2>Applied Jobs</h2>
                <div className="breadcrumbs">
                  <a>Home</a>
                  <a>Dashboard</a>
                  <span>Applied Jobs</span>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
                <NavLink to={canAds?.AppliedJobs_Header_Link} target="_blank">
                  <img
                    src={canAds?.AppliedJobs_Header}
                    alt="Banner Image"
                    className="img-fluid"
                  />
                </NavLink>
              </div>
            </div>
          </div>
          {updatedItems?.length > 0 ? (
            <>
              <div className="twm-right-section-panel candidate-save-job site-bg-gray">
                <SectionRecordsFilter _config={_filterConfig} />
                <div className="twm-jobs-list-wrap">
                  <ul>
                    {currentItems?.map((item) => (
                      <li>
                        <div className="twm-jobs-list-style1 mb-5">
                          <div className="twm-media">
                            <img src={item.ImgUrl} alt="#" />
                          </div>
                          <div className="twm-mid-content">
                            <NavLink
                              to={publicUser.jobs.DETAIL1}
                              className="twm-job-title"
                            >
                              <h4>
                                {item.Title}
                                <span className="twm-job-post-duration">
                                  {item.PostedOn}
                                </span>
                              </h4>
                            </NavLink>
                            <p className="twm-job-address">{item.Address}</p>
                            <a
                              href="#"
                              className="twm-job-websites site-text-primary"
                            >
                              {item.WebsiteURL}
                            </a>
                          </div>
                          <div className="twm-right-content">
                            <div className="twm-jobs-amount">
                              ₹{item.Scale}
                              <span>/month</span>
                            </div>
                            <p>{item.formattedDate}</p>
                          </div>
                          <div className="twm-right-content">
                            <div className="twm-jobs-amount">Status</div>
                            <p>{item.Status}</p>
                          </div>
                          <div className="twm-right-content">
                            <div className="twm-jobs-amount">Action</div>
                            <a
                              data-bs-toggle="modal"
                              href="#saved-jobs-view"
                              role="button"
                              class="custom-toltip"
                            >
                              <span class="fa fa-eye"></span>
                              <span class="custom-toltip-block">View</span>
                            </a>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <ThemeProvider theme={themes}>
                  <Stack spacing={2}>
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={(event, value) => setCurrentPage(value)}
                      shape="rounded"
                    />
                  </Stack>
                </ThemeProvider>
              </div>
            </>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Index />
            </div>
          )}

          <div className="col-xl-12 col-lg-12 col-md-12 mb-3 mt-5">
            <NavLink to={canAds?.AppliedJobs_Footer_Link} target="_blank">
              <img
                src={canAds?.AppliedJobs_Footer}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </>
      )}
    </>
  );
}

export default CanAppliedJobsPage;
const themes = createTheme({
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          padding: "22px",
          fontWeight: "400",
          fontFamily: "Rubik",
          fontSize: "16px",
          lineHeight: "50px",
          height: "50px",
          borderRadius: "12px",
          "&.Mui-selected": {
            backgroundColor: "#046434",
            color: "white",
            "&:hover": {
              backgroundColor: "#046434",
              color: "white",
            },
          },
        },
      },
    },
  },
});
