import { useEffect, useState, useMemo } from "react";
import {
  ThemeProvider,
  createTheme,
  Pagination,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ArticleIcon from "@mui/icons-material/Article";

import moment from "moment";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Index from "../common/no-data/Index";
import { loadScript } from "../../../../globals/constants";
import CircularProgress from "../../../common/loading/Index";
import JobViewPopup from "../../../common/popups/popup-job-view";
import { fetchDashboard } from "../../../../reducers/candidate/CanDashboardSlice";
import { fetchJobAlerts } from "../../../../reducers/candidate/CanJobAlertSlice";

function CanJobAlertPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.CanDashboard.loading);
  const CanJobAlertList = useSelector((state) => state.CanJobAlert);
  const canAds = useSelector((state) => state.CanDashboard.canAds);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);

  // Optional: Set initial state for empty updatedItems
  const [updatedItems, setUpdatedItems] = useState([]);

  const formattedItems = CanJobAlertList?.CanJobAlert?.map((item) => {
    const milliseconds = item.PostedDate.seconds * 1000;
    const totalMilliseconds =
      milliseconds + item.PostedDate.nanoseconds / 1000000;
    const date = new Date(totalMilliseconds);
    const newDate = moment(date).format("MMM Do YYYY");
    return {
      ...item,
      formattedDate: newDate,
    };
  });

  console.log(updatedItems); // This might be empty initially

  const itemsPerPage = 1;
  const totalPages = Math.ceil(formattedItems?.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, formattedItems?.length);
  const currentItems = formattedItems.slice(startIndex, endIndex);

  useMemo(() => {
    window.scrollTo({ top: 0 });
  }, [currentPage]);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) {
      dispatch(fetchDashboard(userUUID));
    }
  }, [dispatch, userUUID]);

  useEffect(() => {
    dispatch(fetchJobAlerts(userUUID));
  }, [dispatch, userUUID]);

  useEffect(() => {
    if (CanJobAlertList?.CanJobAlert?.length > 0) {
      setUpdatedItems(CanJobAlertList.CanJobAlert); // Update state with actual data
    }
  }, [CanJobAlertList]); // Update state when CanJobAlertList changes

  return (
    <>
      {CanJobAlertList.loading === true ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          <div className="wt-admin-right-page-header clearfix">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
                <h2>Job Alerts</h2>
                <div className="breadcrumbs">
                  <a>Home</a>
                  <a>Dashboard</a>
                  <span>Job Alerts</span>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
                <NavLink to={canAds?.JobAlerts_Header_Link} target="_blank">
                  <img
                    src={canAds?.JobAlerts_Header}
                    alt="Banner Image"
                    className="img-fluid"
                  />
                </NavLink>
              </div>
            </div>
          </div>

          {updatedItems !== undefined ? (
            <>
              <div className="twm-right-section-panel candidate-save-job site-bg-gray">
                <div className="product-filter-wrap d-flex justify-content-between align-items-center">
                  <span className="woocommerce-result-count-left">
                    Job Alerts
                  </span>
                  <form
                    className="woocommerce-ordering twm-filter-select"
                    method="get"
                  >
                    <span className="woocommerce-result-count">Short By</span>
                    <select
                      className="wt-select-bar-2 selectpicker"
                      data-live-search="true"
                      data-bv-field="size"
                    >
                      <option>Last 2 Months</option>
                      <option>Last 1 Months</option>
                      <option>15 days ago</option>
                      <option>Weekly</option>
                      <option>Yesterday</option>
                      <option>Today</option>
                    </select>
                  </form>
                </div>
                <div className="table-responsive">
                  <table className="table twm-table table-striped table-borderless">
                    <thead>
                      <tr>
                        <th>Sl.No</th>
                        <th>Title</th>
                        <th>Jobs Description</th>
                        <th style={{ minWidth: "140px" }}>Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems?.map((item, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item.JobTitle}</td>
                            <td>{item.Description}</td>
                            <td>{item.formattedDate}</td>
                            <td>
                              <div class="twm-table-controls">
                                <ul class="twm-DT-controls-icon list-unstyled d-flex justify-content-end">
                                  <li>
                                    {item.Status === false ? (
                                      <>
                                        <Tooltip
                                          title={"Job is No longer Available"}
                                        >
                                          <span>
                                            <IconButton
                                              disabled
                                              style={{
                                                color: "grey",
                                              }}
                                            >
                                              <RemoveRedEyeIcon
                                                style={{
                                                  fontSize: "15px",
                                                }}
                                              />
                                            </IconButton>
                                          </span>
                                        </Tooltip>
                                      </>
                                    ) : (
                                      <a
                                        data-bs-toggle="modal"
                                        href="#saved-jobs-view"
                                        role="button"
                                        class="custom-toltip"
                                      >
                                        <Tooltip title={"View"}>
                                          <IconButton>
                                            <RemoveRedEyeIcon
                                              style={{
                                                fontSize: "15px",
                                                marginTop: "5px",
                                              }}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </a>
                                    )}
                                  </li>
                                  <li>
                                    <Tooltip
                                      title={
                                        item.Status === false
                                          ? "Job is No longer Available"
                                          : "Apply"
                                      }
                                    >
                                      <span>
                                        <IconButton
                                          disabled={item.Status === false}
                                        >
                                          <ArticleIcon
                                            style={{
                                              color:
                                                item.Status === false
                                                  ? "grey"
                                                  : " ",
                                              fontSize: "15px",
                                            }}
                                          />
                                        </IconButton>
                                      </span>
                                    </Tooltip>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <JobViewPopup />

              <ThemeProvider theme={themes}>
                <Stack spacing={2}>
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={(event, value) => setCurrentPage(value)}
                    shape="rounded"
                  />
                </Stack>
              </ThemeProvider>
            </>
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Index />
              </div>
            </>
          )}

          <div className="col-xl-12 col-lg-12 col-md-12 mb-3 mt-5">
            <NavLink to={canAds?.JobAlerts_Footer_Link} target="_blank">
              <img
                src={canAds?.JobAlerts_Footer}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </>
      )}
    </>
  );
}

export default CanJobAlertPage;
const themes = createTheme({
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          padding: "22px",
          fontWeight: "400",
          fontFamily: "Rubik",
          fontSize: "16px",
          lineHeight: "50px",
          height: "50px",
          borderRadius: "12px",
          "&.Mui-selected": {
            backgroundColor: "#046434",
            color: "white",
            "&:hover": {
              backgroundColor: "#046434",
              color: "white",
            },
          },
        },
      },
    },
  },
});
