import React, { useState, useMemo, useEffect } from "react";
import { ThemeProvider, createTheme, Pagination, Stack } from "@mui/material";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Index from "../common/no-data/Index";
import { loadScript } from "../../../../globals/constants";
import CircularProgress from "../../../common/loading/Index";
import { publicUser } from "../../../../globals/route-names";
import { fetchCanDeals } from "../../../../reducers/candidate/CanDealsSlice";
import { fetchDashboard } from "../../../../reducers/candidate/CanDashboardSlice";

function CanDealsPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.CanDashboard.loading);
  const canAds = useSelector((state) => state.CanDashboard.canAds);
  const CanDealsList = useSelector((state) => state.CanDeals.CanDeals);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const CanDeals = useSelector((state) => state.CanDeals);

  const itemsPerPage = 6;
  const totalPages = Math.ceil(CanDealsList?.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, CanDealsList?.length);
  const currentItems = CanDealsList.slice(startIndex, endIndex);

  const dealsData = currentItems?.map((item) => {
    const milliseconds = item.Date.seconds * 1000;
    const totalMilliseconds = milliseconds + item.Date.nanoseconds / 1000000;
    const date = new Date(totalMilliseconds);
    return {
      ...item,
      date: date,
      formattedDate: moment(date).format("MMM Do YYYY"), // Optional formatting
    };
  });

  dealsData.sort((a, b) => b.date - a.date);

  useMemo(() => {
    window.scrollTo({ top: 0 });
  }, [currentPage]);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
    dispatch(fetchCanDeals(userUUID));
  }, [dispatch]);

  return (
    <div>
      {CanDeals.loading === true ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          <div className="wt-admin-right-page-header clearfix">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
                <h2>Deals of the Day</h2>
                <div className="breadcrumbs">
                  <a>Home</a>
                  <a>Dashboard</a>
                  <span>Deals of the Day</span>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
                <NavLink to={canAds?.Deal_Header_Link} target="_blank">
                  <img
                    src={canAds?.Deal_Header}
                    alt="Banner Image"
                    className="img-fluid"
                  />
                </NavLink>
              </div>
            </div>
          </div>

          {currentItems.length > 0 ? (
            <div className="section-full p-t50 p-b90 site-bg-white">
              <div className="container">
                <div className="masonry-wrap row d-flex">
                  {dealsData?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="masonry-item col-lg-4 col-md-12"
                      >
                        <div className="blog-post twm-blog-post-1-outer">
                          <div className="wt-post-media">
                            <NavLink to={item.Link} target="_blank">
                              <img src={item.ImgUrl} alt="Deal Img" />
                            </NavLink>
                          </div>
                          <div className="wt-post-info">
                            <div className="wt-post-meta ">
                              <ul>
                                <li className="post-date">
                                  {item.formattedDate}
                                </li>
                                <li className="post-author">
                                  By{" "}
                                  <NavLink to={item.OfferUrl} target="_blank">
                                    {item.Company}
                                  </NavLink>
                                </li>
                              </ul>
                            </div>
                            <div className="wt-post-title ">
                              <h4 className="post-title">
                                <NavLink to={publicUser.blog.DETAIL}>
                                  {item.Title}
                                </NavLink>
                              </h4>
                            </div>
                            {item.Description.length > 125 ? (
                              <div className="wt-post-text">
                                <p className="text-with-ellipsis">
                                  {item.Description}
                                </p>
                              </div>
                            ) : (
                              <div className="wt-post-text">
                                <p className="text-without-ellipsis">
                                  {item.Description}
                                </p>
                              </div>
                            )}

                            <div className="wt-post-readmore ">
                              <NavLink
                                to={item.Link}
                                className="site-button-link site-text-primary"
                                target="_blank"
                              >
                                Know More
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <ThemeProvider theme={themes}>
                  <Stack spacing={2}>
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={(event, value) => setCurrentPage(value)}
                      shape="rounded"
                    />
                  </Stack>
                </ThemeProvider>
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Index />
            </div>
          )}

          <div className="col-xl-12 col-lg-12 col-md-12 mb-3">
            <NavLink to={canAds?.Dashboard_Footer_Link} target="_blank">
              <img
                src={canAds?.Dashboard_Footer}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </>
      )}
    </div>
  );
}
export default CanDealsPage;

const themes = createTheme({
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          padding: "22px",
          fontWeight: "400",
          fontFamily: "Rubik",
          fontSize: "16px",
          lineHeight: "50px",
          height: "50px",
          borderRadius: "12px",
          "&.Mui-selected": {
            backgroundColor: "#046434",
            color: "white",
            "&:hover": {
              backgroundColor: "#046434",
              color: "white",
            },
          },
        },
      },
    },
  },
});
