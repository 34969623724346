import { createSlice } from "@reduxjs/toolkit";
import { auth } from "../../config/fbConfig";
import { signOut } from "firebase/auth";

//Initial Value of Slice
const initialState = {
  loginStatus: false,
  userUUID: null,
};

//Action of Slice
const authStatusSlice = createSlice({
  name: "AuthStatus",
  initialState,
  reducers: {
    login(state, userId) {
      state.loginStatus = true;
      state.userUUID = userId.payload;
    },
    logout(state) {
      state.loginStatus = false;
      signOut(auth)
        .then(() => {
          console.log("logout success");
        })
        .catch((error) => {
          alert("Error Try Again Later");
        });
    },
  },
});

//Export of Slice
export const { login, logout } = authStatusSlice.actions;
export default authStatusSlice.reducer;
