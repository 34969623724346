import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../../../config/fbConfig";
import JobZImage from "../../../../common/jobz-img";

import {
  canRoute,
  candidate,
  empRoute,
  employer,
  admRoute,
  admin,
} from "../../../../../globals/route-names";

import {
  getAuth,
  signInWithEmailAndPassword,
  getIdTokenResult,
} from "firebase/auth";

function LoginPage() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [role, setRole] = useState("candidate");

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        username,
        password
      );
      const user = userCredential.user;
      if (user) {
        const idTokenResult = await getIdTokenResult(user);
        const customClaims = idTokenResult.claims;

        console.log("Custom Claims:", typeof customClaims.roll);

        if (customClaims.roll === 3) {
          navigate(admRoute(admin.DASHBOARD));
        } else if (customClaims.roll === 2) {
          navigate(canRoute(candidate.DASHBOARD));
        } else {
          navigate(empRoute(employer.DASHBOARD));
        }
      }
    } catch (error) {
      setError("Invalid credential");
    }
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    setError(""); // Clear the error when the user starts typing
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError(""); // Clear the error when the user starts typing
  };

  return (
    <div className="section-full site-bg-white">
      <div className="container-fluid">
        <div className="twm-log-reg-media">
          <div className="row">
            <div className="col-xl-8 col-lg-6 col-md-5 twm-log-reg-media-wrap">
              <div className="twm-log-reg-media">
                <div className="twm-l-media">
                  <JobZImage src="images/lacnar-img/login.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-7">
              <div className="twm-log-reg-form-wrap">
                <div className="twm-log-reg-inner">
                  <div className="twm-log-reg-head">
                    <div className="twm-log-reg-logo">
                      <span className="log-reg-form-title">Log In</span>
                    </div>
                  </div>
                  <div className="twm-tabs-style-2">
                    {/* <ul className="nav nav-tabs" id="myTab2" role="tablist">
                      <li className="nav-item">
                        <button
                          className={`nav-link ${
                            role === "candidate" ? "active" : ""
                          }`}
                          onClick={() => setRole("candidate")}
                          type="button"
                        >
                          <i className="fas fa-user-tie" />
                          Candidate
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className={`nav-link ${
                            role === "employer" ? "active" : ""
                          }`}
                          onClick={() => setRole("employer")}
                          type="button"
                        >
                          <i className="fas fa-building" />
                          Employer
                        </button>
                      </li>
                    </ul> */}
                    <div className="tab-content" id="myTab2Content">
                      <div
                        className="tab-pane fade show active"
                        id="twm-login-candidate"
                      >
                        <form onSubmit={handleLogin}>
                          <div className="form-group mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Username"
                              value={username}
                              onChange={handleUsernameChange}
                            />
                          </div>
                          <div className="form-group mb-3">
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Password"
                              value={password}
                              onChange={handlePasswordChange}
                            />
                          </div>
                          <div className="col-lg-12">
                            <div className="twm-forgot-wrap">
                              <div className="form-group mb-3">
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="Password4"
                                  />
                                  <label
                                    className="form-check-label rem-forgot"
                                    htmlFor="Password4"
                                  >
                                    Remember me{" "}
                                    <a href="#" className="site-text-primary">
                                      Forgot Password
                                    </a>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <button type="submit" className="site-button">
                                Log in
                              </button>
                              {error && (
                                <div
                                  style={{ marginTop: "6px" }}
                                  className="text-danger"
                                >
                                  {error}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <span className="center-text-or">Or</span>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <button type="submit" className="site-button">
                                Sign Up
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
