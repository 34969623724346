import { createSlice } from "@reduxjs/toolkit";
import { getDocs, collection } from "firebase/firestore";
import { firestore } from "../../config/fbConfig";

//Initial Value of Slice
const initialState = {
  EmpCandidateSearch: [
    {
      Id: "Primary-1",
      Title: "Suresh R",
      Address: "Mysore",
      Category: "Medical Officer",
      AppliedDate: "16/06/2023",
      AppliedTime: "11:35 am",
      JobStatus: "Pending",
      ImgUrl: "https://c2m-edutech.com/demo/person1.jpg",
    },
    {
      Id: "Primary-2",
      Title: "Pavan Shankar",
      Address: "Bengalore",
      Category: "HR Manager",
      AppliedDate: "26/09/2023",
      AppliedTime: "11:35 pm",
      JobStatus: "Approved",
      ImgUrl: "https://c2m-edutech.com/demo/person2.jpg",
    },
  ],
  loading: false,
  error: null,
};

//Action of Slice
const EmpCandidateSearchSlice = createSlice({
  name: "EmpCandidateSearch",
  initialState,
  reducers: {
    setEmpCandidateSearchStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setEmpCandidateSearchSuccess: (state, action) => {
      state.loading = false;
      state.EmpCandidateSearch = action.payload;
    },
    setEmpCandidateSearchFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

//Export of Slice
export const {
  setEmpCandidateSearchStart,
  setEmpCandidateSearchSuccess,
  setEmpCandidateSearchFailure,
} = EmpCandidateSearchSlice.actions;
export default EmpCandidateSearchSlice.reducer;

export const fetchEmpCandidateSearch = (userUUID) => async (dispatch) => {
  try {
    dispatch(setEmpCandidateSearchStart());
    const querySnapshot = await getDocs(
      collection(firestore, "Users", userUUID, "EmpCandidateSearch")
    );
    const EmpCandidateSearch = [];
    querySnapshot.forEach((doc) => {
      EmpCandidateSearch.push(doc.data());
    });
    dispatch(setEmpCandidateSearchSuccess(EmpCandidateSearch));
  } catch (error) {
    dispatch(setEmpCandidateSearchFailure(error.message));
  }
};
