import React, { useEffect } from "react";
import { Tooltip } from "@mui/material";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ArticleIcon from "@mui/icons-material/Article";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

import moment from "moment";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Index from "../common/no-data/Index";
import { loadScript } from "../../../../globals/constants";
import CircularProgress from "../../../common/loading/Index";
import JobViewPopup from "../../../common/popups/popup-job-view";
import { fetchDashboard } from "../../../../reducers/candidate/CanDashboardSlice";
import { fetchSavedJobs } from "../../../../reducers/candidate/CanSavedJobsSlice";

function CanSavedJobsPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.CanDashboard.loading);
  const savedJobsList = useSelector((state) => state.CanSavedJobs);
  const canAds = useSelector((state) => state.CanDashboard.canAds);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);

  const updatedItems = savedJobsList?.CanSavedJobs?.map((item) => {
    const milliseconds = item.PostedDate.seconds * 1000;
    const totalMilliseconds =
      milliseconds + item.PostedDate.nanoseconds / 1000000;
    const date = new Date(totalMilliseconds);
    const newDate = moment(date).format("MMM Do YYYY");

    return {
      ...item,
      formattedDate: newDate,
    };
  });

  console.log(savedJobsList);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchSavedJobs(userUUID));
  }, [dispatch, userUUID]);

  useEffect(() => {
    if (updatedItems && updatedItems.length > 0) {
      $(document).ready(function () {
        $("#jobs_bookmark_table").DataTable();
      });
    }
  }, [updatedItems]);

  return (
    <>
      {savedJobsList && savedJobsList.loading ? (
        <CircularProgress />
      ) : (
        <>
          <div className="wt-admin-right-page-header clearfix">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
                <h2>Saved Jobs</h2>
                <div className="breadcrumbs">
                  <a>Home</a>
                  <a>Dashboard</a>
                  <span>Saved Jobs</span>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
                <NavLink to={canAds?.SavedJobs_Header_Link} target="_blank">
                  <img
                    src={canAds?.SavedJobs_Header}
                    alt="Banner Image"
                    className="img-fluid"
                  />
                </NavLink>
              </div>
            </div>
          </div>

          {updatedItems && updatedItems.length > 0 ? (
            <>
              <div className="twm-right-section-panel candidate-save-job site-bg-gray">
                <div className="twm-D_table table-responsive">
                  <table
                    id="jobs_bookmark_table"
                    className="table table-bordered twm-candidate-save-job-list-wrap"
                  >
                    <thead>
                      <tr>
                        <th>Sl No</th>
                        <th>Job Title</th>
                        <th>Company</th>
                        <th>Posted Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {updatedItems?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <div className="twm-candidate-save-job-list">
                                <div className="twm-media">
                                  <div className="twm-media-pic">
                                    <img src={item.Logo} alt="#" />
                                  </div>
                                </div>
                                <div className="twm-mid-content">
                                  <a
                                    href="/job-detail/1"
                                    className="twm-job-title"
                                  >
                                    <h4>{item.JobTitle}</h4>
                                  </a>
                                </div>
                              </div>
                            </td>
                            <td>
                              <a href="#">{item.Company}</a>
                            </td>
                            <td>
                              <div>{item.formattedDate}</div>
                            </td>
                            <td>
                              <div className="col-6">
                                <div className="twm-table-controls">
                                  <ul className="twm-DT-controls-icon list-unstyled d-flex justify-content-end">
                                    <li>
                                      {item.Status === false ? (
                                        <>
                                          <Tooltip
                                            title={"Job is No longer Available"}
                                          >
                                            <span>
                                              <IconButton
                                                disabled
                                                style={{
                                                  color: "grey",
                                                }}
                                              >
                                                <RemoveRedEyeIcon
                                                  style={{
                                                    fontSize: "15px",
                                                  }}
                                                />
                                              </IconButton>
                                            </span>
                                          </Tooltip>
                                        </>
                                      ) : (
                                        <>
                                          <a
                                            data-bs-toggle="modal"
                                            href="#saved-jobs-view"
                                            role="button"
                                            className="custom-toltip"
                                          >
                                            <Tooltip title={"View"}>
                                              <span>
                                                <IconButton>
                                                  <RemoveRedEyeIcon
                                                    style={{
                                                      fontSize: "15px",
                                                    }}
                                                  />
                                                </IconButton>
                                              </span>
                                            </Tooltip>
                                          </a>
                                        </>
                                      )}
                                    </li>
                                    <li>
                                      <Tooltip
                                        title={
                                          item.Status === false
                                            ? "Job is No longer Available"
                                            : "Apply"
                                        }
                                      >
                                        <span>
                                          <IconButton
                                            disabled={item.Status === false}
                                          >
                                            <ArticleIcon
                                              style={{
                                                color:
                                                  item.Status === false
                                                    ? "grey"
                                                    : " ",
                                                fontSize: "15px",
                                              }}
                                            />
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                    </li>
                                    <li>
                                      <Tooltip title="delete">
                                        <IconButton>
                                          <DeleteIcon
                                            style={{
                                              fontSize: "15px",
                                            }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>Sl No</th>
                        <th>Job Title</th>
                        <th>Company</th>
                        <th>Posted Date</th>
                        <th>Action</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>

              <JobViewPopup />
            </>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Index />
            </div>
          )}

          <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mt-5">
            <NavLink to={canAds?.SavedJobs_Footer_Link} target="_blank">
              <img
                src={canAds?.SavedJobs_Footer}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </>
      )}
    </>
  );
}

export default CanSavedJobsPage;
