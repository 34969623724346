import { NavLink } from "react-router-dom";
import { publicUrlFor } from "../../../../../../globals/constants";
import { publicUser } from "../../../../../../globals/route-names";

function SectionSideAdvert() {
  return (
    <>
      <div
        className="twm-advertisment"
        style={{ backgroundImage: `url(${publicUrlFor("images/add-bg.jpg")})` }}
      >
        <div className="overlay" />
        <h4 className="twm-title">Recruiting?</h4>
        <p>
          Get Best Matched Jobs On your <br />
          Email. Add Resume NOW!
        </p>
        <NavLink to={publicUser.pages.ABOUT} className="site-button white">
          Read More
        </NavLink>
      </div>
    </>
  );
}

export default SectionSideAdvert;
