function SectionLocation() {
  return (
    <>
      <h4 className="section-head-small mb-4">Location</h4>
      <div className="twm-s-map-iframe">
        {/* <iframe
          height={270}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3304.8534521658976!2d-118.2533646842856!3d34.073270780600225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c6fd9829c6f3%3A0x6ecd11bcf4b0c23a!2s1363%20Sunset%20Blvd%2C%20Los%20Angeles%2C%20CA%2090026%2C%20USA!5e0!3m2!1sen!2sin!4v1620815366832!5m2!1sen!2sin"
        /> */}

        <iframe
          width="100%"
          height="100%"
          frameBorder="0"
          style={{ border: 0 }}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3929.008319038118!2d76.6210289!3d12.2859745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baf701edfc7809d%3A0x85e1d4a255e9e36f!2zMTLCsDE3JzA5LjUiTiA3NsKwMzcnMjUuMCJF!5e0!3m2!1sen!2sin!4v1698259640135!5m2!1sen!2sin"
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
          title="Location Map"
        />
      </div>
    </>
  );
}

export default SectionLocation;
