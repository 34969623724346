import { createSlice } from "@reduxjs/toolkit";

//Initial Value of Slice
const initialState = {
    List: [
        {
            Id : "Primary-1",
        }
    ]
}

//Action of Slice
const termsConditionsSlice = createSlice({
    name: "TermsConditions",
    initialState,
    reducers: {}
})

//Export of Slice
export const { } = termsConditionsSlice.actions;
export default termsConditionsSlice.reducer;