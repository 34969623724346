import { createSlice } from "@reduxjs/toolkit";
import { getDocs, collection } from "firebase/firestore";
import { firestore } from "../../config/fbConfig";

//Initial Value of Slice
const initialState = {
  CanDeals: [],
  loading: false,
  error: null,
};

//Action of Slice
const CanDealsSlice = createSlice({
  name: "CanDeals",
  initialState,
  reducers: {
    setCanDealsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setCanDealsSuccess: (state, action) => {
      state.loading = false;
      state.CanDeals = action.payload;
    },
    setCanDealsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

//Export of Slice
export const { setCanDealsStart, setCanDealsSuccess, setCanDealsFailure } =
  CanDealsSlice.actions;

export default CanDealsSlice.reducer;

export const fetchCanDeals = (userUUID) => async (dispatch) => {
  try {
    dispatch(setCanDealsStart());
    const querySnapshot = await getDocs(
      collection(firestore, "Users", userUUID, "Deals")
    );
    const CanDeals = [];
    querySnapshot.forEach((doc) => {
      CanDeals.push(doc.data());
    });
    dispatch(setCanDealsSuccess(CanDeals));
  } catch (error) {
    dispatch(setCanDealsFailure(error.message));
  }
};
