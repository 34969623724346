import React, { useContext, useEffect, useState } from "react";
import { OnlineStatusContext } from "./OnlineStatusProvider";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const modalStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "300px",
  bgcolor: "background.paper",

  boxShadow: "2px 4px  grey",
  p: 4,
  textAlign: "center",
};

const OfflineNotification = () => {
  const isOnline = useContext(OnlineStatusContext);
  const [open, setOpen] = useState(!isOnline);

  useEffect(() => {
    setOpen(!isOnline);
  }, [isOnline]);

  return (
    <Modal
      open={open}
      onClose={() => {}}
      aria-labelledby="offline-notification-title"
      aria-describedby="offline-notification-description"
      BackdropProps={{
        onClick: (event) => event.stopPropagation(),
      }}
    >
      <Box sx={modalStyle}>
        <h4 id="offline-notification-title">You are offline</h4>
        <p id="offline-notification-description">
          Please check your internet connection.
        </p>
      </Box>
    </Modal>
  );
};

export default OfflineNotification;
