import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IconButton, Tooltip } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";

const validationSchema = Yup.object().shape({
  can_whatsapp: Yup.string().required("Whatsapp is required"),
  can_instagram: Yup.string(),
  can_linkedin: Yup.string(),
  can_facebook: Yup.string(),
});

function SectionCandidateSocialInfo({ socialNetwork }) {
  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <Formik
      initialValues={{
        can_whatsapp: socialNetwork?.Whatsapp || "",
        can_instagram: socialNetwork?.Instagram || "",
        can_linkedin: socialNetwork?.Linkedin || "",
        can_facebook: socialNetwork?.Facebook || "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Handle form submission here
        console.log(values);
        setIsEditMode(false); // Disable edit mode after submitting the form
      }}
    >
      {({ errors, touched, values }) => (
        <Form>
          <div className="panel panel-default">
            <div className="panel-heading wt-panel-heading p-a20">
              <h4 className="panel-tittle m-a0">Social Network</h4>
            </div>
            <div className="edit-button-end">
              {isEditMode === false ? (
                <Tooltip title="Edit">
                  <IconButton onClick={() => setIsEditMode(!isEditMode)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Close">
                  <IconButton onClick={() => setIsEditMode(!isEditMode)}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <div className="panel-body wt-panel-body p-a20 m-b30">
              <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-12">
                  <div className="form-group">
                    <label>Whatsapp</label>
                    <Field
                      className="form-control"
                      id="can_whatsapp"
                      name="can_whatsapp"
                      type="text"
                      placeholder="+91 98625 65744"
                      value={values.can_whatsapp}
                      pattern="[0-9]*"
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      component="div"
                      name="can_whatsapp"
                      className="error"
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-12">
                  <div className="form-group">
                    <label>Linkedin</label>
                    <Field
                      className="form-control"
                      id="can_linkedin"
                      name="can_linkedin"
                      type="text"
                      value={values.can_linkedin}
                      placeholder="https://in.linkedin.com/"
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      component="div"
                      name="can_linkedin"
                      className="error"
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-12">
                  <div className="form-group">
                    <label>Instagram</label>
                    <Field
                      className="form-control"
                      id="can_instagram"
                      name="can_instagram"
                      value={values.can_instagram}
                      type="text"
                      placeholder="https://www.instagram.com/"
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      component="div"
                      name="can_instagram"
                      className="error"
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-12">
                  <div className="form-group">
                    <label>Facebook</label>
                    <Field
                      className="form-control"
                      id="can_facebook"
                      name="can_facebook"
                      value={values.can_facebook}
                      type="text"
                      placeholder="https://www.facebook.com/"
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      component="div"
                      name="can_facebook"
                      className="error"
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="text-left">
                    <button
                      type="submit"
                      className="site-button"
                      disabled={!isEditMode}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SectionCandidateSocialInfo;
