import React, { useEffect, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Index from "../../candidate/common/no-data/Index";
import CircularProgress from "../../../common/loading/Index";
import { loadScript } from "../../../../globals/constants";
import { fetchDashboard } from "../../../../reducers/employer/EmpDashboardSlice";
import { fetchEmpPaymentHistory } from "../../../../reducers/employer/EmpPaymentHistorySlice";
import { IconButton } from "@mui/material";

function EmpPaymentHistoryPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const empAds = useSelector((state) => state.EmpDashboard.empAds);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const paymentHistoryList = useSelector((state) => state.EmpPaymentHistory);

  console.log(paymentHistoryList);

  const updatedData = paymentHistoryList?.EmpPaymentHistory?.map((item) => {
    const milliseconds = item.CreatedDate.seconds * 1000;
    const totalMilliseconds =
      milliseconds + item.CreatedDate.nanoseconds / 1000000;
    const date = new Date(totalMilliseconds);
    return {
      ...item,
      date: date,
      formattedDate: moment(date).format("MMM Do YYYY"), // Optional formatting
    };
  });

  const handleDownloadTableData = (data) => {
    const formattedData = formatDataForDownload(data);
    downloadCSV(formattedData);
  };

  const formatDataForDownload = (data) => {
    let csvContent = "Transaction ID, Name ,Amount,Date,Status\n";
    data.forEach((item) => {
      csvContent += `${item.TransactionID},${item.BillName},${item.Amount},${item.formattedDate},${item.Status}\n`;
    });
    return csvContent;
  };

  const downloadCSV = (csvData) => {
    const blob = new Blob([csvData], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "table_data.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
    dispatch(fetchEmpPaymentHistory(userUUID));
  }, [dispatch]);

  return (
    <>
      {paymentHistoryList.loading === true ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          <div className="wt-admin-right-page-header clearfix">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
                <h2>Payment History</h2>
                <div className="breadcrumbs">
                  <a>Home</a>
                  <a>Dashboard</a>
                  <span>Payment History</span>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
                <NavLink to={empAds?.Payment_Header_Link} target="_blank">
                  <img
                    src={empAds?.Payment_Header}
                    alt="Banner Image"
                    className="img-fluid"
                  />
                </NavLink>
              </div>
            </div>
          </div>

          {paymentHistoryList?.EmpPaymentHistory?.length > 0 ? (
            <div className="twm-pro-view-chart-wrap">
              <div className="col-lg-12 col-md-12 mb-4">
                <div className="panel panel-default site-bg-white m-t30">
                  <div className="panel-heading wt-panel-heading p-a20">
                    <h4 className="panel-tittle m-a0">
                      <i className="fas fa-money-bill" /> Payment History
                    </h4>
                  </div>
                  <div className="panel-body wt-panel-body">
                    <div className="twm-D_table p-a20 table-responsive">
                      <table
                        id="payment_history_table"
                        className="table table-bordered twm-payment-history-list-wrap"
                      >
                        <thead>
                          <tr>
                            <th>Transaction ID</th>
                            <th>Name</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {updatedData?.map((item) => (
                            <tr key={item.TransactionID}>
                              <td>{item.TransactionID}</td>
                              <td>{item.BillName}</td>
                              <td>{item.Amount}</td>
                              <td>{item.formattedDate}</td>
                              <td>
                                <span
                                  className={
                                    item.Status === "Success"
                                      ? "text-clr-green2"
                                      : "text-clr-red"
                                  }
                                >
                                  {item.Status}
                                </span>
                              </td>
                              <td>
                                <div className="twm-table-controls">
                                  <ul className="twm-DT-controls-icon list-unstyled">
                                    <li>
                                      <button
                                        title="View details"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                      >
                                        <span className="fa fa-eye" />
                                      </button>
                                    </li>
                                    <li>
                                      <IconButton
                                        onClick={() => {
                                          handleDownloadTableData(updatedData);
                                        }}
                                      >
                                        <DownloadIcon fontSize="small" />
                                      </IconButton>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>Transaction ID</th>
                            <th>Name</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Index />
            </div>
          )}

          <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mt-5">
            <NavLink to={empAds?.Payment_Footer_Link} target="_blank">
              <img
                src={empAds?.Payment_Footer}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </>
      )}
    </>
  );
}

export default EmpPaymentHistoryPage;
