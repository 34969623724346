import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loadScript } from "../../../../globals/constants";
import { fetchDashboard } from "../../../../reducers/candidate/CanDashboardSlice";
import SectionCandicateBasicInfo from "../sections/profile/section-can-basic-info";
import SectionCandidateSocialInfo from "../sections/profile/section-can-social-info";
import SectionCandicateAddressInfo from "../sections/profile/section-can-address-info";

function CanProfilePage() {
  const dispatch = useDispatch();
  const canAds = useSelector((state) => state.CanDashboard.canAds);
  const loading = useSelector((state) => state.CanDashboard.loading);
  const canProfile = useSelector((state) => state.CanDashboard.canProfile);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);

  console.log(canProfile);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
  }, [dispatch]);

  return (
    <>
      <div className="col-xl-12 col-lg-12 col-md-12 mb-4">
        <NavLink to={canAds?.Profile_Header_Link} target="_blank">
          <img
            src={canAds?.Profile_Header}
            alt="Banner Image"
            className="img-fluid"
          />
        </NavLink>
      </div>
      <div className="twm-right-section-panel site-bg-gray">
        {/*Basic Information*/}
        <SectionCandicateBasicInfo profileData={canProfile?.Basic} />
        {/*Social Network*/}
        <SectionCandidateSocialInfo socialNetwork={canProfile?.SocialNetwork} />
        {/*Social Network*/}
        <SectionCandicateAddressInfo Address={canProfile?.Address} />
      </div>
      <div className="col-xl-12 col-lg-12 col-md-12 mb-4">
        <NavLink to={canAds?.Profile_Footer_Link} target="_blank">
          <img
            src={canAds?.Profile_Footer}
            alt="Banner Image"
            className="img-fluid"
          />
        </NavLink>
      </div>
    </>
  );
}

export default CanProfilePage;
