import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DropzoneComponent } from "react-dropzone-component";
import ModalPopUp from "../../../common/ModalPopUp";
import Index from "../../candidate/common/no-data/Index";
import { loadScript } from "../../../../globals/constants";
import CircularProgress from "../../../common/loading/Index";
import { fetchDashboard } from "../../../../reducers/employer/EmpDashboardSlice";
import { fetchEmpTickets } from "../../../../reducers/employer/EmpTicketsSlice";

function EmpTicketsPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const empAds = useSelector((state) => state.EmpDashboard.empAds);
  const ticketsList = useSelector((state) => state.EmpTickets);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading) dispatch(fetchDashboard(userUUID));
    dispatch(fetchEmpTickets(userUUID));
  }, [dispatch, loading, userUUID]);

  const updatedData = ticketsList?.EmpTickets?.map((item) => {
    const milliseconds =
      item.CreatedAt.seconds * 1000 + item.CreatedAt.nanoseconds / 1000000;
    const date = new Date(milliseconds);
    return {
      ...item,
      date,
      formattedDate: moment(date).format("MMM Do YYYY"),
    };
  });

  console.log(updatedData);

  const handleInputChange = (event) => {
    console.log(event);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const componentConfig = {
    postUrl: "upload.php",
  };

  return (
    <>
      {ticketsList.loading ? (
        <CircularProgress />
      ) : (
        <>
          <div className="wt-admin-right-page-header clearfix">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
                <h2>Tickets</h2>
                <div className="breadcrumbs">
                  <a href="#">Home</a>
                  <a href="#">Dashboard</a>
                  <span>Tickets</span>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
                <NavLink to={empAds?.Tickets_Header_Link} target="_blank">
                  <img
                    src={empAds?.Tickets_Header}
                    alt="Banner Image"
                    className="img-fluid"
                  />
                </NavLink>
              </div>
            </div>
          </div>

          <div className="twm-pro-view-chart-wrap">
            <div className="col-lg-12 col-md-12 mb-4">
              <div className="panel panel-default site-bg-white m-t30">
                <div className="panel-heading wt-panel-heading p-a20">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h4 className="panel-title m-a0">
                      <i className="far fa-bookmark" /> Tickets
                    </h4>
                    <button
                      onClick={() => setOpen(true)}
                      className="site-button m-r5"
                    >
                      Add Tickets
                    </button>
                  </div>
                </div>

                {ticketsList?.EmpTickets?.length > 0 ? (
                  <div className="panel-body wt-panel-body">
                    <div className="twm-D_table p-a20 table-responsive">
                      <table
                        id="jobs_bookmark_table"
                        className="table table-bordered twm-bookmark-list-wrap"
                      >
                        <thead>
                          <tr>
                            <th>Sl.No</th>
                            <th>Ticket Title</th>
                            <th>Description</th>
                            <th>Created Date</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {updatedData.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <div className="twm-bookmark-list">
                                  <div className="twm-mid-content">
                                    <a href="#" className="twm-job-title">
                                      <h4>{item.Subject}</h4>
                                    </a>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="twm-job-post-duration">
                                  {item.Description}
                                </div>
                              </td>
                              <td>{item.formattedDate}</td>
                              <td>
                                <span className="text-clr-green2">
                                  {item.Status === true ? "Open" : "Closed"}
                                </span>
                              </td>
                              <td>
                                <div className="twm-table-controls">
                                  <ul className="twm-DT-controls-icon list-unstyled">
                                    <li>
                                      <button
                                        title="View profile"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                      >
                                        <span className="fa fa-eye" />
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>Sl.No</th>
                            <th>Ticket Title</th>
                            <th>Description</th>
                            <th>Created Date</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "3rem",
                    }}
                  >
                    <Index />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mt-4">
            <NavLink to={empAds?.Tickets_Footer_Link} target="_blank">
              <img
                src={empAds?.Tickets_Footer}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>

          <ModalPopUp title={"Add Ticket Form"} open={open} close={handleClose}>
            <>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Description</label>
                  <textarea
                    className="form-control"
                    id="Description"
                    name="description"
                    rows={3}
                    placeholder="Greetings! We are Galaxy Software Development Company."
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div>
                <Typography>Upload Files</Typography>
              </div>
              <div className="panel-body wt-panel-body p-a20 m-b30 ">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <DropzoneComponent
                        config={componentConfig}
                        id="photo"
                        name="photo"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="text-left">
                      <button type="submit" className="site-button">
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </ModalPopUp>
        </>
      )}
    </>
  );
}

export default EmpTicketsPage;
