import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loadScript } from "../../../../globals/constants";
import { fetchDashboard } from "../../../../reducers/candidate/CanDashboardSlice";

function CanSearchJobPage() {
  const dispatch = useDispatch();
  const CanSearchJobList = useSelector((state) => state.CanSearchJob.List);
  const canAds = useSelector((state) => state.CanDashboard.canAds);
  const loading = useSelector((state) => state.CanDashboard.loading);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
  }, [dispatch]);

  return (
    <div>
      <div className="wt-admin-right-page-header clearfix">
        <div className="row">
          <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
            <div className="wt-admin-right-page-header clearfix">
              <h2>Search Jobs</h2>
              <div className="breadcrumbs">
                <a>Home</a>
                <a>Dasboard</a>
                <span>Search Jobs</span>
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
            <NavLink to={canAds?.SearchJobs_Header_Link} target="_blank">
              <img
                src={canAds?.SearchJobs_Header}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </div>
      </div>

      <div className="twm-pro-view-chart-wrap">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="panel panel-default site-bg-white m-t30">
            <div className="panel-heading wt-panel-heading p-a20">
              <h4 className="panel-tittle m-a0">
                <i className="far fa-list-alt" />
                All Jobs
              </h4>
            </div>
            <div className="panel-body wt-panel-body">
              <div className="twm-D_table p-a20 table-responsive">
                <table
                  id="candidate_data_table"
                  className="table table-bordered"
                >
                  <thead>
                    <tr>
                      <th>Sl.No</th>
                      <th>Company Name</th>
                      <th>Designation</th>
                      <th>Posted Date</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {CanSearchJobList?.map((item, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          <div className="twm-DT-candidates-list">
                            <div className="twm-media">
                              <div className="twm-media-pic">
                                <img src={item.ImgUrl} alt="#" />
                              </div>
                            </div>
                            <div className="twm-mid-content">
                              <a href="#" className="twm-job-title">
                                <h4>{item.Title}</h4>
                                <p className="twm-candidate-address">
                                  <i className="feather-map-pin" />
                                  {item.Address}
                                </p>
                              </a>
                            </div>
                          </div>
                        </td>
                        <td>{item.Designation}</td>
                        <td>
                          {item.Date} at {item.Time}
                        </td>
                        <td>
                          <div className="twm-table-controls">
                            <ul className="twm-DT-controls-icon list-unstyled">
                              <li>
                                <button
                                  title="View profile"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                >
                                  <span className="fa fa-eye" />
                                </button>
                              </li>
                              <li>
                                <button
                                  title="Send message"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                >
                                  <span className="far fa-envelope-open" />
                                </button>
                              </li>
                              <li>
                                <button
                                  title="Delete"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                >
                                  <span className="far fa-trash-alt" />
                                </button>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>

                  <tfoot>
                    <tr>
                      <th>Sl.No</th>
                      <th>Company Name</th>
                      <th>Designation</th>
                      <th>Posted Date</th>
                      <th />
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Job Search End*/}
      <div className="col-xl-12 col-lg-12 col-md-12 mb-3 mt-5">
        <NavLink to={canAds?.SearchJobs_Footer_Link} target="_blank">
          <img
            src={canAds?.SearchJobs_Footer}
            alt="Banner Image"
            className="img-fluid"
          />
        </NavLink>
      </div>
    </div>
  );
}
export default CanSearchJobPage;
