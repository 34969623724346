import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Index from "../../candidate/common/no-data/Index";
import { loadScript } from "../../../../globals/constants";
import { fetchDashboard } from "../../../../reducers/employer/EmpDashboardSlice";
import { fetchEmpNotification } from "../../../../reducers/employer/EmpNotificationSlice";

function EmpNotificationPage() {
  // Load custom script on component mount
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const empAds = useSelector((state) => state.EmpDashboard.empAds);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const EmpNotification = useSelector((state) => state.EmpNotification);

  console.log(EmpNotification);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
    dispatch(fetchEmpNotification(userUUID));
  }, [dispatch]);

  return (
    <>
      {EmpNotification.loading === true ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          <div className="wt-admin-right-page-header clearfix">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
                <h2>Notifications </h2>
                <div className="breadcrumbs">
                  <a>Home</a>
                  <a>Dashboard</a>
                  <span>Notifications</span>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
                <NavLink to={empAds?.Noti_Header_Link} target="_blank">
                  <img
                    src={empAds?.Noti_Header}
                    alt="Banner Image"
                    className="img-fluid"
                  />
                </NavLink>
              </div>
            </div>
          </div>

          {EmpNotification?.EmpNotification?.length > 0 ? (
            <div className="twm-pro-view-chart-wrap">
              <div className="col-lg-12 col-md-12 mb-4">
                <div className="panel panel-default site-bg-white m-t30">
                  <div className="panel-heading wt-panel-heading p-a20">
                    <h4 className="panel-tittle m-a0">
                      <i className="fas fa-money-bill" /> Notifications
                    </h4>
                  </div>
                  <div className="panel-body wt-panel-body">
                    <div className="twm-D_table p-a20 table-responsive">
                      <table
                        id="payment_history_table"
                        className="table table-bordered twm-payment-history-list-wrap"
                      >
                        <thead>
                          <tr>
                            <th>Transaction ID</th>
                            <th>Name</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {EmpNotification?.EmpNotification?.map((item) => (
                            <tr key={item.TransactionID}>
                              <td>{item.TransactionID}</td>
                              <td>{item.Name}</td>
                              <td>{item.Amount}</td>
                              <td>{item.Date}</td>
                              <td>
                                <span
                                  className={
                                    item.Status === "Success"
                                      ? "text-clr-green2"
                                      : "text-clr-red"
                                  }
                                >
                                  {item.Status}
                                </span>
                              </td>
                              <td>
                                <div className="twm-table-controls">
                                  <ul className="twm-DT-controls-icon list-unstyled">
                                    <li>
                                      <button
                                        title="View details"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                      >
                                        <span className="fa fa-eye" />
                                      </button>
                                    </li>
                                    {/* Add more action buttons as needed */}
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>Transaction ID</th>
                            <th>Name</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Index />
            </div>
          )}

          <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mt-5">
            <NavLink to={empAds?.Noti_Footer_Link} target="_blank">
              <img
                src={empAds?.Noti_Footer}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </>
      )}
    </>
  );
}

export default EmpNotificationPage;
