import { createSlice } from "@reduxjs/toolkit";
import { getDocs, collection } from "firebase/firestore";
import { firestore } from "../../config/fbConfig";

//Initial Value of Slice
const initialState = {
  CanJobAlert: [],
  loading: false,
  error: null,
};

const CanJobAlertSlice = createSlice({
  name: "CanJobAlert",
  initialState,
  reducers: {
    setJobAlertStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setJobAlertSuccess: (state, action) => {
      state.loading = false;
      state.CanJobAlert = action.payload;
    },
    setJobAlertFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

//Export of Slice
export const { setJobAlertStart, setJobAlertSuccess, setJobAlertFailure } =
  CanJobAlertSlice.actions;
export default CanJobAlertSlice.reducer;

export const fetchJobAlerts = (userUUID) => async (dispatch) => {
  try {
    dispatch(setJobAlertStart());
    const querySnapshot = await getDocs(
      collection(firestore, "Users", userUUID, "JobAlerts")
    );
    const jobAlerts = [];
    querySnapshot.forEach((doc) => {
      jobAlerts.push(doc.data());
    });

    dispatch(setJobAlertSuccess(jobAlerts));
  } catch (error) {
    dispatch(setJobAlertFailure(error.message));
  }
};
