import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Divider } from "@mui/material";

function ModalPopUp(props) {
  console.log(props);
  const handleClose = () => {
    props.close();
  };
  return (
    <div>
      <React.Fragment>
        <Dialog
          open={props.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle> </DialogTitle>
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {props.title}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <DialogContent>{props.children}</DialogContent>

          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="modal-footer">
              <button
                type="button"
                style={{ padding: "6px", width: "62px" }}
                className="site-button"
                data-bs-dismiss="modal"
                onClick={handleClose}
              >
                No
              </button>
              <button
                type="button"
                className="site-button outline-primary"
                data-bs-dismiss="modal"
                style={{ padding: "6px", width: "62px" }}
              >
                Yes
              </button>
            </div>
          </div> */}
        </Dialog>
      </React.Fragment>
    </div>
  );
}

export default ModalPopUp;
