import * as React from "react";
import JobZImage from "../jobz-img";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { publicUser, candidate, canRoute } from "../../../globals/route-names";
import { popupType } from "../../../globals/constants";
import YesNoPopup from "../../../app/common/popups/popup-yes-no";

function Header1({ _config }) {
  const authStatus = useSelector((state) => state.AuthStatus.loginStatus);
  const [menuActive, setMenuActive] = useState(false);
  function handleNavigationClick() {
    setMenuActive(!menuActive);
  }

  return (
    <>
      <header
        className={
          "site-header " +
          _config.style +
          " mobile-sider-drawer-menu " +
          (menuActive ? "active" : "")
        }
      >
        <div className="sticky-header main-bar-wraper navbar-expand-lg">
          <div className="main-bar">
            <div className="container-fluid clearfix">
              <div className="logo-header">
                <div className="logo-header-inner logo-header-one">
                  <NavLink to="/">
                    {_config.withBlackLogo ? (
                      <JobZImage src="images/logo-12.png" alt="" />
                    ) : _config.withWhiteLogo ? (
                      <JobZImage src="images/logo-white.png" alt="" />
                    ) : _config.withLightLogo ? (
                      <>
                        <JobZImage
                          id="skin_header_logo_light"
                          src="images/logo-light-3.png"
                          alt=""
                          className="default-scroll-show"
                        />
                        <JobZImage
                          id="skin_header_logo"
                          src="images/logo-dark.png"
                          alt=""
                          className="on-scroll-show"
                        />
                      </>
                    ) : (
                      <JobZImage
                        id="skin_header_logo"
                        src="images/logo-dark.png"
                        alt=""
                      />
                    )}
                  </NavLink>
                </div>
              </div>
              {/* NAV Toggle Button */}
              <button
                id="mobile-side-drawer"
                data-target=".header-nav"
                data-toggle="collapse"
                type="button"
                className="navbar-toggler collapsed"
                onClick={handleNavigationClick}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar icon-bar-first" />
                <span className="icon-bar icon-bar-two" />
                <span className="icon-bar icon-bar-three" />
              </button>
              {/* MAIN Vav */}
              <div className="nav-animation header-nav navbar-collapse collapse d-flex justify-content-center">
                <ul className=" nav navbar-nav">
                  <li className="has-child">
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li className="has-child">
                    <NavLink to="/about-us">About Us</NavLink>
                  </li>
                  <li className="has-child">
                    <NavLink to="/job-list">Search Jobs</NavLink>
                  </li>
                  <li className="has-child">
                    <NavLink to="/blog-grid/1">Deals</NavLink>
                  </li>
                  <li className="has-child">
                    <NavLink to="/blog-list">Blog</NavLink>
                  </li>
                  <li className="has-child">
                    <NavLink to="/contact-us">Contact</NavLink>
                  </li>
                </ul>
              </div>

              <div className="extra-nav header-2-nav">
                <div className="extra-cell">
                  <div className="header-search">
                    <a href="#search" className="header-search-icon">
                      <i className="feather-search" />
                    </a>
                  </div>
                </div>

                {authStatus === false ? (
                  <div className="extra-cell">
                    <div className="header-nav-btn-section">
                      <div className="twm-nav-btn-left">
                        <NavLink
                          to={publicUser.pages.SIGNUP}
                          className="twm-nav-sign-up"
                          activeClassName="active"
                          role="button"
                        >
                          <i className="feather-log-in" /> Sign Up
                        </NavLink>
                      </div>
                      <div className="twm-nav-btn-right">
                        <NavLink
                          to={publicUser.pages.LOGIN}
                          className="twm-nav-post-a-job"
                          activeClassName="active"
                          role="button"
                        >
                          <i className="feather-log-in" /> Sign In
                        </NavLink>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="extra-cell">
                    <div className="header-nav-btn-section">
                      <div className="twm-nav-btn-left">
                        <NavLink
                          to={canRoute(candidate.DASHBOARD)}
                          className="twm-nav-sign-up"
                          activeClassName="active"
                          role="button"
                        >
                          <i className="feather-log-in" /> Dashboard
                        </NavLink>
                      </div>
                      <div className="twm-nav-btn-right">
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#logout-dash-profile"
                        >
                          <button className="twm-nav-post-a-job">
                            <i className="feather-log-out" /> Logout
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div id="search">
            <span className="close" />
            <form
              role="search"
              id="searchform"
              action="/search"
              method="get"
              className="radius-xl"
            >
              <input
                className="form-control"
                name="q"
                type="search"
                placeholder="Type to search"
              />
              <span className="input-group-append">
                <button type="button" className="search-btn">
                  <i className="fa fa-paper-plane" />
                </button>
              </span>
            </form>
          </div>
        </div>
      </header>
      <YesNoPopup
        id="logout-dash-profile"
        type={popupType.LOGOUT}
        msg={"Are you sure you want to log out?"}
      />
    </>
  );
}

export default Header1;
