import { useEffect, useMemo } from "react";

import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { canRoute, candidate } from "../../../../globals/route-names";
import { loadScript } from "../../../../globals/constants";
import CircularProgress from "../../../common/loading/Index";
import SectionCandidateInbox from "../sections/dashboard/section-can-inbox";
import { fetchDashboard } from "../../../../reducers/candidate/CanDashboardSlice";
import SectionCandidateOverview from "../sections/dashboard/section-can-overview";
import SectionCandidateRecentActivities from "../sections/dashboard/section-can-activities";
import SectionCandidateRecentApplications from "../sections/dashboard/section-can-applications";

import React, { useState } from "react";
import * as XLSX from "xlsx";

import jsPDF from "jspdf";
import * as autoTable from "jspdf-autotable";

function CanDashboardPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.CanDashboard.loading);
  const canAds = useSelector((state) => state.CanDashboard.canAds);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const canProfile = useSelector((state) => state.CanDashboard.canProfile);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
  }, [dispatch]);

  useEffect(() => {
    if (canProfile === undefined) {
      navigate(canRoute(candidate.PROFILE));
    }
  }, []);

  useEffect(() => {
    if (!loading && canProfile === undefined) {
      navigate(canRoute(candidate.PROFILE));
    }
  }, [loading, canProfile]);

  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState([]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const parseExcelFile = () => {
    if (!file) {
      console.error("No file selected");
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      try {
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        setData(jsonData);
        setErrors([]); // Clear previous errors
      } catch (error) {
        console.error("Error parsing Excel file:", error);
        setErrors(["Error parsing Excel file"]); // Generic error for now
      }
    };

    reader.readAsArrayBuffer(file);
  };

  // Define your expected format for data validation
  const expectedFormat = {
    Name: "string",
    Fees: "number",
    // Define other columns and their expected data types
  };

  const validateData = () => {
    if (!data.length) return; // No data to validate

    const validationErrors = [];
    data.forEach((row, index) => {
      Object.keys(row).forEach((key) => {
        if (typeof row[key] !== expectedFormat[key]) {
          validationErrors.push(
            `Mismatch in data type at row ${index + 1}, column ${key}`
          );
        }
      });
    });

    setErrors(validationErrors);
  };

  const jsonData = [
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    {
      name: "John Doe",
      age: 30,
      city: "New York",
      add: "sdfsdaf",
      gender: "male",
    },
    // Add more objects as needed
  ];

  const handleDownloadPDF = () => {
    // Create a new PDF instance
    const doc = new jsPDF();

    // Set document properties
    doc.setProperties({
      title: "JSON Data to PDF",
      author: "Your Name",
    });

    // Define table headers
    const headers = Object.keys(jsonData[0]);

    // Define table data
    const data = jsonData.map((obj) => Object.values(obj));

    // Add table to the PDF
    doc.autoTable({
      head: [headers],
      body: data,
    });

    // Save the PDF
    doc.save("json_data.pdf");
  };

  const handleDownloadPDFRecepit = () => {
    // Create a new PDF instance
    const doc = new jsPDF("p", "mm", [210, 297]);

    // Define the receipt image
    const receiptImage = new Image();
    receiptImage.src = "your_receipt_image_base64"; // replace 'your_receipt_image_base64' with the base64 encoded string of your image

    receiptImage.onload = function () {
      // Draw the receipt image on the canvas
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = receiptImage.width;
      canvas.height = receiptImage.height;
      ctx.drawImage(receiptImage, 0, 0);

      // Overlay text on top of the image
      ctx.font = "12px Arial";
      ctx.fillStyle = "black";
      ctx.fillText("Date: 2024-06-07", 100, 100); // Example text, adjust coordinates as needed
      // Add more text as needed

      // Convert canvas to base64 image
      const dataURL = canvas.toDataURL("image/jpeg");

      // Add the image to the PDF
      doc.addImage(dataURL, "JPEG", 10, 10, 190, 277); // Adjust coordinates and size as needed

      // Save the PDF
      doc.save("receipt.pdf");
    };
  };

  return (
    <>
      {loading === true ? (
        <CircularProgress />
      ) : (
        <div className="twm-right-section-panel site-bg-gray">
          <SectionCandidateOverview />
          {/* <div className="twm-pro-view-chart-wrap">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 mb-4">
                <SectionCandidateInbox />
              </div>
              <div className="col-lg-12 col-md-12 mb-4">
                <SectionCandidateRecentActivities />
              </div>
              <div className="col-lg-12 col-md-12 mb-4">
                <SectionCandidateRecentApplications />
              </div>
            </div>
          </div> */}

          <div className="col-xl-12 col-lg-12 col-md-12 mb-4">
            <NavLink to={canAds?.Dashboard_Footer_Link} target="_blank">
              <img
                src={canAds?.Dashboard_Footer}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </div>
      )}

      {/* <div>
        <button onClick={handleDownloadPDF}>Download PDF</button>
      </div>
      <div>
        <h2>Receipt</h2>
        <button onClick={handleDownloadPDFRecepit}>Download PDsdsdF</button>
      </div>
      <>
        <div>
          <input type="file" onChange={handleFileChange} />
          <button onClick={parseExcelFile}>Parse Excel</button>
          <button onClick={validateData}>Validate Data</button>
          {errors.length > 0 && (
            <ul>
              {errors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          )}
          {data.length > 0 && <pre>{JSON.stringify(data, null, 2)}</pre>}
        </div>
      </> */}
    </>
  );
}

export default CanDashboardPage;
