import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IconButton, Tooltip } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";

const validationSchema = Yup.object().shape({
  address_1: Yup.string().required("Address Line 1 is required"),
  address_2: Yup.string(),
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  district: Yup.string().required("District is required"),
  pincode: Yup.string().required("Pincode is required"),
});

const districts = [
  { id: 1, name: "Bangalore Urban" },
  { id: 2, name: "Bangalore Rural" },
  { id: 3, name: "Bagalkot" },
  { id: 4, name: "Ballari" },
  { id: 5, name: "Belagavi" },
  { id: 6, name: "Bidar" },
  { id: 7, name: "Chamarajanagar" },
  { id: 8, name: "Chikkaballapur" },
  { id: 9, name: "Chikkamagaluru" },
  { id: 10, name: "Chitradurga" },
  { id: 11, name: "Dakshina Kannada" },
  { id: 12, name: "Davanagere" },
  { id: 13, name: "Dharwad" },
  { id: 14, name: "Gadag" },
  { id: 15, name: "Kalaburagi" },
  { id: 16, name: "Hassan" },
  { id: 17, name: "Haveri" },
  { id: 18, name: "Kodagu" },
  { id: 19, name: "Kolar" },
  { id: 20, name: "Koppal" },
  { id: 21, name: "Mandya" },
  { id: 22, name: "Mysuru" },
  { id: 23, name: "Raichur" },
  { id: 24, name: "Ramanagara" },
  { id: 25, name: "Shivamogga" },
  { id: 26, name: "Tumakuru" },
  { id: 27, name: "Udupi" },
  { id: 28, name: "Uttara Kannada" },
  { id: 29, name: "Vijayapura" },
  { id: 30, name: "Yadgir" },
];

const states = [{ id: 1, name: "Karnataka" }];

function SectionCandidateAddressInfo({ Address }) {
  const [isEditMode, setIsEditMode] = useState(false);

  const handleSubmit = (values, { setSubmitting }) => {
    console.log(values); // Print form values to console
    // Here you can perform any submission logic, such as API calls
    // After submission, you might want to reset form values or perform other actions
    setIsEditMode(false); // Disable edit mode after submitting the form
    setSubmitting(false); // Set submitting to false after submission is complete
  };

  return (
    <Formik
      initialValues={{
        address_1: Address?.Address_1 || "",
        address_2: Address?.Address_2 || "",
        country: Address?.Country || "",
        state: Address?.State || "",
        district: Address?.District || "",
        pincode: Address?.Pincode || "",
      }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit} // Pass handleSubmit function to onSubmit prop
    >
      {({ errors, touched, values, isSubmitting }) => (
        <Form>
          <div className="panel panel-default">
            <div className="panel-heading wt-panel-heading p-a20">
              <h4 className="panel-title m-a0">Address Information</h4>
            </div>
            <div className="edit-button-end">
              {isEditMode === false ? (
                <Tooltip title="Edit">
                  <IconButton onClick={() => setIsEditMode(!isEditMode)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Close">
                  <IconButton onClick={() => setIsEditMode(!isEditMode)}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <div className="panel-body wt-panel-body p-a20 m-b30">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="form-group city-outer-bx has-feedback">
                    <label>Address Line 1</label>
                    <Field
                      className="form-control"
                      name="address_1"
                      type="text"
                      placeholder="Enter Address Line 1"
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      component="div"
                      name="address_1"
                      className="error-message"
                    />
                  </div>
                  <div className="form-group city-outer-bx has-feedback">
                    <label>Address Line 2</label>
                    <Field
                      className="form-control"
                      id="address_2"
                      name="address_2"
                      type="text"
                      placeholder="Mysuru, Karnataka 570023"
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      component="div"
                      name="address_2"
                      className="error-message"
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-12">
                  <div className="form-group city-outer-bx has-feedback">
                    <label>State</label>
                    <Field
                      as="select"
                      className="form-control"
                      id="state"
                      name="state"
                      disabled={!isEditMode}
                    >
                      <option value="">Select State</option>
                      {states.map((state) => (
                        <option key={state.id}>{state.name}</option>
                      ))}
                    </Field>
                    <ErrorMessage
                      component="div"
                      name="state"
                      className="error-message"
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-12">
                  <div className="form-group city-outer-bx has-feedback">
                    <label>District</label>
                    <Field
                      as="select"
                      className="form-control"
                      id="district"
                      name="district"
                      disabled={!isEditMode}
                    >
                      <option value="">Select District</option>
                      {districts.map((district) => (
                        <option key={district.id}>{district.name}</option>
                      ))}
                    </Field>
                    <ErrorMessage
                      component="div"
                      name="district"
                      className="error-message"
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-lg-12 col-md-12">
                  <div className="form-group city-outer-bx has-feedback">
                    <label>Pincode</label>
                    <Field
                      className="form-control"
                      id="pincode"
                      name="pincode"
                      type="text"
                      placeholder="75462"
                      pattern="[0-9]*"
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      component="div"
                      name="pincode"
                      className="error-message"
                    />
                  </div>
                </div>
              </div>
              <div className="text-left">
                <button
                  type="submit"
                  className="site-button"
                  disabled={!isEditMode || isSubmitting}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SectionCandidateAddressInfo;
