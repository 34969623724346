import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { publicUrlFor } from "../../../../../globals/constants";
import { publicUser } from "../../../../../globals/route-names";

function SectionContact() {
  const navigate = useNavigate();
  return (
    <>
      <h4 className="section-head-small mb-4">Contact us</h4>
      <div className="twm-s-contact">
        <div className="row">
          <div
            className="twm-advertisment"
            style={{
              backgroundImage: `url(${publicUrlFor("images/add-bg.jpg")})`,
            }}
          >
            <div className="overlay" />
            <h4 className="twm-title">Contact us</h4>
            <p>
              Whether you have a question, need assistance, or want to provide
              feedback, our team is here to help
            </p>
            <NavLink
              to={publicUser.pages.CONTACT}
              className="site-button white"
            >
              Contact us
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionContact;
