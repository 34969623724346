import { NavLink, useNavigate } from "react-router-dom";
import JobZImage from "../../../../common/jobz-img";
import { canRoute, candidate, empRoute, employer, publicUser } from "../../../../../globals/route-names";
import { useState } from "react";

function SignUpPage() {
    const navigate = useNavigate();

    const [canSignUpUsername, setCanSignUpUsername] = useState('');
    const [canSignUpEmail, setCanSignUpEmail] = useState('');
    const [canSignUpPassword, setCanSignUpPassword] = useState('');
    const [canSignUpConfirmPassword, setCanSignUpConfirmPassword] = useState('');

    const [empSignUpUsername, setEmpSignUpUsername] = useState('');
    const [empSignUpEmail, setEmpSignUpEmail] = useState('');
    const [empSignUpPassword, setEmpSignUpPassword] = useState('');
    const [empSignUpConfirmPassword, setEmpSignUpConfirmPassword] = useState('');

    const handleCandidateSignUp = (event) => {
        event.preventDefault();
        signupCandidate();
    }

    const handleEmployerSignUp = (event) => {
        event.preventDefault();
        signupEmployer();
    }

    const signupCandidate = () => {
        // Form processing logic for candidate signup
        // For example, you might make an API call here
        console.log('Candidate signup processing logic');

        // Assuming the signup is successful, navigate to candidate dashboard
        moveToCandidate();
    }

    const signupEmployer = () => {
        // Form processing logic for employer signup
        // For example, you might make an API call here
        console.log('Employer signup processing logic');

        // Assuming the signup is successful, navigate to employer dashboard
        moveToEmployer();
    }

    const moveToCandidate = () => {
        navigate(canRoute(candidate.DASHBOARD));
    }

    const moveToEmployer = () => {
        navigate(empRoute(employer.DASHBOARD));
    }

    return (
        <>
            <div className="section-full site-bg-white p-t100">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-8 col-lg-6 col-md-5 twm-log-reg-media-wrap">
                            <div className="twm-log-reg-media">
                                <div className="twm-l-media">
                                    <JobZImage src="images/lacnar-img/signup.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-7">
                            <div className="twm-log-reg-form-wrap">
                                <div className="twm-log-reg-inner">
                                    <div className="twm-log-reg-head">
                                        <div className="twm-log-reg-logo">
                                            <span className="log-reg-form-title">Sign Up</span>
                                        </div>
                                    </div>
                                    <div className="twm-tabs-style-2">
                                        <ul className="nav nav-tabs" id="myTab2" role="tablist">
                                            <li className="nav-item">
                                                <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#twm-signup-candidate" type="button"><i className="fas fa-user-tie" />Candidate</button>
                                            </li>
                                            <li className="nav-item">
                                                <button className="nav-link" data-bs-toggle="tab" data-bs-target="#twm-signup-employer" type="button"><i className="fas fa-building" />Employer</button>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="myTab2Content">
                                            <form onSubmit={handleCandidateSignUp} className="tab-pane fade show active" id="twm-signup-candidate">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group mb-3">
                                                            <input
                                                                name="username"
                                                                type="text"
                                                                required
                                                                className="form-control"
                                                                placeholder="Username*"
                                                                value={canSignUpUsername}
                                                                onChange={(event) => {
                                                                    setCanSignUpUsername(event.target.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group mb-3">
                                                            <input
                                                                name="email"
                                                                type="email"
                                                                required
                                                                className="form-control"
                                                                placeholder="Email*"
                                                                value={canSignUpEmail}
                                                                onChange={(event) => {
                                                                    setCanSignUpEmail(event.target.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group mb-3">
                                                            <input
                                                                name="password"
                                                                type="password"
                                                                className="form-control"
                                                                required
                                                                placeholder="Password*"
                                                                value={canSignUpPassword}
                                                                onChange={(event) => {
                                                                    setCanSignUpPassword(event.target.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group mb-3">
                                                            <input
                                                                name="confirmPassword"
                                                                type="password"
                                                                className="form-control"
                                                                required
                                                                placeholder="Confirm Password*"
                                                                value={canSignUpConfirmPassword}
                                                                onChange={(event) => {
                                                                    setCanSignUpConfirmPassword(event.target.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <button type="submit" className="site-button">Sign Up as Candidate</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <form onSubmit={handleEmployerSignUp} className="tab-pane fade" id="twm-signup-employer">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group mb-3">
                                                            <input
                                                                name="username"
                                                                type="text"
                                                                required
                                                                className="form-control"
                                                                placeholder="Username*"
                                                                value={empSignUpUsername}
                                                                onChange={(event) => {
                                                                    setEmpSignUpUsername(event.target.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group mb-3">
                                                            <input
                                                                name="email"
                                                                type="email"
                                                                required
                                                                className="form-control"
                                                                placeholder="Email*"
                                                                value={empSignUpEmail}
                                                                onChange={(event) => {
                                                                    setEmpSignUpEmail(event.target.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group mb-3">
                                                            <input
                                                                name="password"
                                                                type="password"
                                                                className="form-control"
                                                                required
                                                                placeholder="Password*"
                                                                value={empSignUpPassword}
                                                                onChange={(event) => {
                                                                    setEmpSignUpPassword(event.target.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group mb-3">
                                                            <input
                                                                name="confirmPassword"
                                                                type="password"
                                                                className="form-control"
                                                                required
                                                                placeholder="Confirm Password*"
                                                                value={empSignUpConfirmPassword}
                                                                onChange={(event) => {
                                                                    setEmpSignUpConfirmPassword(event.target.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <button type="submit" className="site-button">Sign Up as Employer</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SignUpPage;
