import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Index from "../../candidate/common/no-data/Index";
import { loadScript } from "../../../../globals/constants";
import { fetchDashboard } from "../../../../reducers/employer/EmpDashboardSlice";
import { fetchEmpCandidateSearch } from "../../../../reducers/employer/EmpCandidateSearchSlice";

function EmpCandidatesPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const empAds = useSelector((state) => state.EmpDashboard.empAds);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const searchCandidateList = useSelector((state) => state.EmpCandidateSearch);

  console.log(searchCandidateList);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
    dispatch(fetchEmpCandidateSearch(userUUID));
  }, [dispatch]);

  return (
    <>
      {searchCandidateList?.loading === true ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          <div className="wt-admin-right-page-header clearfix">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
                <h2>Candidates</h2>
                <div className="breadcrumbs">
                  <a>Home</a>
                  <a>Dashboard</a>
                  <span>Candidates</span>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
                <NavLink to={empAds?.Search_Header_Link} target="_blank">
                  <img
                    src={empAds?.Search_Header}
                    alt="Banner Image"
                    className="img-fluid"
                  />
                </NavLink>
              </div>
            </div>
          </div>

          {searchCandidateList?.EmpCandidateSearch.length > 0 ? (
            <div className="twm-pro-view-chart-wrap">
              <div className="col-lg-12 col-md-12 mb-4">
                <div className="panel panel-default site-bg-white m-t30">
                  <div className="panel-heading wt-panel-heading p-a20">
                    <h4 className="panel-tittle m-a0">
                      <i className="far fa-list-alt" />
                      All Candidates
                    </h4>
                  </div>
                  <div className="panel-body wt-panel-body">
                    <div className="twm-D_table p-a20 table-responsive">
                      <table
                        id="candidate_data_table"
                        className="table table-bordered"
                      >
                        <thead>
                          <tr>
                            <th>Sl.No</th>
                            <th>Name</th>
                            <th>Applied for</th>
                            <th>Date</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {searchCandidateList?.EmpCandidateSearch?.map(
                            (item, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                <td>
                                  <div className="twm-DT-candidates-list">
                                    <div className="twm-media">
                                      <div className="twm-media-pic">
                                        <img src={item.ImgUrl} alt="" />
                                      </div>
                                    </div>
                                    <div className="twm-mid-content">
                                      <a href="#" className="twm-job-title">
                                        <h4>{item.Title}</h4>
                                        <p className="twm-candidate-address">
                                          <i className="feather-map-pin" />
                                          {item.Address}
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </td>
                                <td>{item.Category}</td>
                                <td>
                                  {item.AppliedDate} at {item.AppliedTime}
                                </td>
                                <td>
                                  <div className="twm-table-controls">
                                    <ul className="twm-DT-controls-icon list-unstyled">
                                      <li>
                                        <button
                                          title="View profile"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                        >
                                          <span className="fa fa-eye" />
                                        </button>
                                      </li>
                                      <li>
                                        <button
                                          title="Send message"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                        >
                                          <span className="far fa-envelope-open" />
                                        </button>
                                      </li>
                                      <li>
                                        <button
                                          title="Delete"
                                          data-bs-toggle="modal"
                                          data-bs-target="#logout-delete-profile"
                                        >
                                          <span className="far fa-trash-alt" />
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th />
                            <th>Name</th>
                            <th>Applied for</th>
                            <th>Date</th>
                            <th />
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Index />
            </div>
          )}

          <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mt-4">
            <NavLink to={empAds?.Search_Footer_Link} target="_blank">
              <img
                src={empAds?.Search_Footer}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </>
      )}
    </>
  );
}
export default EmpCandidatesPage;
