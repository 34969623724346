import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IconButton, Tooltip } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  description: Yup.string().required("Description is required"),
  DOB: Yup.string().required("DOB is required"),
});

function SectionCandidateBasicInfo({ profileData }) {
  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <Formik
      initialValues={{
        firstName: profileData?.FirstName || "",
        lastName: profileData?.LastName || "",
        DOB: profileData?.DOB || "",
        description: profileData?.Description || "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        console.log(values);
        setIsEditMode(false); // Disable edit mode after submitting the form
      }}
    >
      {({ errors, touched, values }) => (
        <Form>
          <div className="panel panel-default">
            <div className="panel-heading wt-panel-heading p-a20">
              <h4 className="panel-tittle m-a0">Basic Informations</h4>
            </div>
            <div className="edit-button-end">
              {isEditMode === false ? (
                <Tooltip title="Edit">
                  <IconButton onClick={() => setIsEditMode(!isEditMode)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Close">
                  <IconButton onClick={() => setIsEditMode(!isEditMode)}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <div className="panel-body wt-panel-body p-a20 m-b30">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="form-group">
                    <label>First Name</label>
                    <Field
                      className="form-control"
                      name="firstName"
                      type="text"
                      placeholder="Neha"
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      component="div"
                      name="firstName"
                      className="error"
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="form-group">
                    <label>Last Name</label>
                    <Field
                      className="form-control"
                      name="lastName"
                      type="text"
                      placeholder="Shankar"
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      component="div"
                      name="lastName"
                      className="error"
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="form-group">
                    <label>DOB</label>
                    <Field
                      className="form-control"
                      name="DOB"
                      type="text"
                      placeholder="25/09/1995"
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      component="div"
                      name="DOB"
                      className="error"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Description</label>
                    <Field
                      className="form-control"
                      name="description"
                      as="textarea"
                      rows={3}
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      component="div"
                      name="description"
                      className="error"
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="text-left">
                    <button
                      type="submit"
                      className="site-button"
                      disabled={!isEditMode}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SectionCandidateBasicInfo;
