import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCCIKpHrczTSzYUBk5YfaAJ6pGXn5KHfw0",
  authDomain: "lacnar-project.firebaseapp.com",
  projectId: "lacnar-project",
  storageBucket: "lacnar-project.appspot.com",
  appId: "1:935798407581:web:1b8931416ce9a19c446f74",
  measurementId: "G-7N094S98RW",
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { auth, firestore, storage };
