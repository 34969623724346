import { Route, Routes } from "react-router-dom";
import { employer } from "../globals/route-names";

import EmpDashboardPage from "../app/pannels/employer/components/emp-dashboard";
import EmpDealsPage from "../app/pannels/employer/components/emp-deals";
import EmpCompanyProfilePage from "../app/pannels/employer/components/emp-company-profile";
import EmpPostAJobPage from "../app/pannels/employer/components/jobs/emp-post-a-job";
import EmpManageJobsPage from "../app/pannels/employer/components/jobs/emp-manage-jobs";
import EmpCandidatesPage from "../app/pannels/employer/components/emp-candidates";
import EmpBookmarksPage from "../app/pannels/employer/components/emp-bookmarks";
import EmpPackagesPage from "../app/pannels/employer/components/emp-packages";
import EmpMessages1Page from "../app/pannels/employer/components/messages/emp-messages1";
import EmpMessages2Page from "../app/pannels/employer/components/messages/emp-messages2";
import EmpNotificationPage from "../app/pannels/employer/components/emp-notifications";
import EmpResumeAlertsPage from "../app/pannels/employer/components/emp-resume-alerts";

import EmpPaymentSPage from "../app/pannels/employer/components/emp-payments";
import EmpFaqPage from "../app/pannels/employer/components/emp-faq";

import Error404Page from "../app/pannels/public-user/components/pages/error404";

import EmpUpdatePasswordPage from "../app/pannels/employer/components/emp-update-password";
import EmpTicketsPage from "../app/pannels/employer/components/emp-tickets";
import EmpPricingPage from "../app/pannels/employer/components/emp-pricing";
import EmpInventory from "../app/pannels/employer/components/emp-inventory";
import EmpCart from "../app/pannels/employer/components/emp-cart";
import Contact from "../app/pannels/employer/components/emp-contact";

function EmployerRoutes() {
  return (
    <Routes>
      <Route path={employer.DASHBOARD} element={<EmpDashboardPage />} />
      <Route path={employer.DEAL} element={<EmpDealsPage />} />
      <Route path={employer.PROFILE} element={<EmpCompanyProfilePage />} />
      <Route path={employer.POST_A_JOB} element={<EmpPostAJobPage />} />
      <Route path={employer.MANAGE_JOBS} element={<EmpManageJobsPage />} />
      <Route path={employer.CANDIDATES} element={<EmpCandidatesPage />} />
      <Route path={employer.BOOKMARKS} element={<EmpBookmarksPage />} />
      <Route path={employer.PACKAGES} element={<EmpPackagesPage />} />

      <Route path={employer.MESSAGES1} element={<EmpMessages1Page />} />
      <Route path={employer.MESSAGES2} element={<EmpMessages2Page />} />
      <Route path={employer.NOTIFICATIONS} element={<EmpNotificationPage />} />
      <Route path={employer.RESUME_ALERTS} element={<EmpResumeAlertsPage />} />
      <Route path={employer.PAYMENTS} element={<EmpPaymentSPage />} />
      <Route path={employer.INVENTORY} element={<EmpInventory />} />
      <Route path={employer.FAQ} element={<EmpFaqPage />} />
      <Route path="*" element={<Error404Page />} />
      <Route
        path={employer.UPDATE_PASSWORD}
        element={<EmpUpdatePasswordPage />}
      />
      <Route path={employer.TICKETS} element={<EmpTicketsPage />} />
      <Route path={employer.PRICING} element={<EmpPricingPage />} />
      <Route path={employer.CART} element={<EmpCart />} />
      <Route path={employer.CONTACT} element={<Contact />} />
    </Routes>
  );
}

export default EmployerRoutes;
