import { NavLink } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";
import { ThemeProvider, createTheme, Pagination } from "@mui/material";
import JobZImgae from "../../../../common/jobz-img";
import { publicUser } from "../../../../../globals/route-names";
import SectionPagination from "../../sections/common/section-pagination";
import moment from "moment/moment";

import { useSelector, useDispatch } from "react-redux";
import { fetchDeals } from "../../../../../reducers/public-user/blogGridSlice";

function BlogGrid1Page() {
  const dispatch = useDispatch();
  const blogGrid = useSelector((state) => state.blogGrid.deals);

  console.log(blogGrid);

  const itemsPerPage = 6;
  const totalPages = Math.ceil(blogGrid?.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, blogGrid?.length);
  const currentItems = blogGrid.slice(startIndex, endIndex);

  const dealsData = currentItems?.map((item) => {
    const milliseconds = item.Date.seconds * 1000;
    const totalMilliseconds = milliseconds + item.Date.nanoseconds / 1000000;
    const date = new Date(totalMilliseconds);
    return {
      ...item,
      date: date,
      formattedDate: moment(date).format("MMM Do YYYY"), // Optional formatting
    };
  });
  dealsData.sort((a, b) => b.date - a.date);

  console.log(dealsData);

  useMemo(() => {
    window.scrollTo({ top: 0 });
  }, [currentPage]);

  useEffect(() => {
    dispatch(fetchDeals());
  }, [dispatch]);

  return (
    <>
      <div className="section-full p-t120  p-b90 site-bg-white">
        <div className="container">
          <div className="masonry-wrap row d-flex">
            {dealsData?.map((item, index) => {
              return (
                <>
                  <div key={index} className="masonry-item col-lg-4 col-md-12">
                    <div className="blog-post twm-blog-post-1-outer">
                      <div className="wt-post-media">
                        <NavLink to={item?.link} target="_blank">
                          <img src={item.ImgUrl} alt="image" />
                        </NavLink>
                      </div>

                      <div className="wt-post-info">
                        <div className="wt-post-meta ">
                          <ul>
                            <li className="post-date">{item.formattedDate}</li>
                            <li className="post-author">
                              By{" "}
                              <NavLink NavLink to={item?.link} target="_blank">
                                {item.Author}
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                        <div className="wt-post-title ">
                          <h4 className="post-title">
                            <NavLink NavLink to={item?.link} target="_blank">
                              {item.Title}
                            </NavLink>
                          </h4>
                        </div>

                        {item.Description.length > 125 ? (
                          <div className="wt-post-text">
                            <p className="text-with-ellipsis">
                              {item.Description}
                            </p>
                          </div>
                        ) : (
                          <div className="wt-post-text">
                            <p className="text-without-ellipsis">
                              {item.Description}
                            </p>
                          </div>
                        )}

                        <div className="wt-post-readmore ">
                          <NavLink
                            NavLink
                            to={item?.link}
                            target="_blank"
                            className="site-button-link site-text-primary"
                          >
                            Read More
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <ThemeProvider theme={themes}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(event, value) => setCurrentPage(value)}
              shape="rounded"
            />
          </ThemeProvider>
        </div>
      </div>
    </>
  );
}

export default BlogGrid1Page;
const themes = createTheme({
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          padding: "22px",
          fontWeight: "400",
          fontFamily: "Rubik",
          fontSize: "16px",
          lineHeight: "50px",
          height: "50px",
          borderRadius: "12px",
          "&.Mui-selected": {
            backgroundColor: "#046434",
            color: "white",
            "&:hover": {
              backgroundColor: "#046434",
              color: "white",
            },
          },
        },
      },
    },
  },
});
