import { createSlice } from "@reduxjs/toolkit";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { firestore } from "../../config/fbConfig";
//Initial Value of Slice
const initialState = {
  empAds: [],
  canDashboard: [],
  empProfile: [],
  empMessages: [],
  empNotifications: [],
  empInventory: {},
  loading: true,
  error: null,
};

const EmpDashboardSlice = createSlice({
  name: "EmpDashboard",
  initialState,
  reducers: {
    setDashboardStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setDashboardSuccess: (state, action) => {
      state.loading = false;
      state.canDashboard = action.payload.Dashboard;
      state.empAds = action.payload.Ads;
      state.empProfile = action.payload.Profile;
      state.empMessages = action.payload.Messages;
      state.empNotifications = action.payload.Notifications;
      state.empInventory = action.payload.Inventory;
    },
    setDashboardFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

//Export of Slice
export const { setDashboardStart, setDashboardSuccess, setDashboardFailure } =
  EmpDashboardSlice.actions;
export default EmpDashboardSlice.reducer;

export const fetchDashboard = (userUUID) => async (dispatch) => {
  try {
    dispatch(setDashboardStart());
    const docRef = doc(firestore, "Users", userUUID);

    const unsubscribe = onSnapshot(
      docRef,
      (docSnap) => {
        if (docSnap.exists()) {
          const dashboardData = docSnap.data();
          dispatch(setDashboardSuccess(dashboardData));
        } else {
          dispatch(setDashboardFailure("Document not found"));
        }
      },
      (error) => {
        dispatch(setDashboardFailure(error.message));
      }
    );
  } catch (error) {
    dispatch(setDashboardFailure(error.message));
  }
};
