import { createSlice } from "@reduxjs/toolkit";
import { getDocs, collection } from "firebase/firestore";
import { firestore } from "../../config/fbConfig";

//Initial Value of Slice
const initialState = {
  CanNotification: [
    {
      Id: "Primary-1",
      title: "we've just released new feature",
      description:
        "We maintain a list of notifications in the component's state",
    },
    {
      Id: "Primary-1",
      title: "we've just released new feature",
      description:
        "We maintain a list of notifications in the component's state",
    },
    {
      Id: "Primary-1",
      title: "we've just released new feature",
      description:
        "We maintain a list of notifications in the component's state",
    },
    {
      Id: "Primary-1",
      title: "we've just released new feature",
      description:
        "We maintain a list of notifications in the component's state",
    },
    {
      Id: "Primary-1",
      title: "we've just released new feature",
      description:
        "We maintain a list of notifications in the component's state",
    },
    {
      Id: "Primary-1",
      title: "we've just released new feature",
      description:
        "We maintain a list of notifications in the component's state",
    },
    {
      Id: "Primary-1",
      title: "we've just released new feature",
      description:
        "We maintain a list of notifications in the component's state",
    },
  ],
  loading: false,
  error: null,
};

//Action of Slice
const CanNotificationSlice = createSlice({
  name: "CanNotification",
  initialState,
  reducers: {
    setCanNotificationStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setCanNotificationSuccess: (state, action) => {
      state.loading = false;
      state.CanNotification = action.payload;
    },
    setCanNotificationFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

//Export of Slice
export const {
  setCanNotificationStart,
  setCanNotificationSuccess,
  setCanNotificationFailure,
} = CanNotificationSlice.actions;
export default CanNotificationSlice.reducer;

export const fetchCanNotification = (userUUID) => async (dispatch) => {
  try {
    dispatch(setCanNotificationStart());
    const querySnapshot = await getDocs(
      collection(firestore, "Users", userUUID, "Notifications")
    );
    const CanNotification = [];
    querySnapshot.forEach((doc) => {
      CanNotification.push(doc.data());
    });
    dispatch(setCanNotificationSuccess(CanNotification));
  } catch (error) {
    dispatch(setCanNotificationFailure(error.message));
  }
};
