import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Index from "../common/no-data/Index";
import CircularProgress from "../../../common/loading/Index";
import { loadScript } from "../../../../globals/constants";
import SectionCanITSkills from "../sections/resume/section-can-itskills";
import SectionCanProjects from "../sections/resume/section-can-projects";
import SectionCanKeySkills from "../sections/resume/section-can-keyskills";
import SectionCanEducation from "../sections/resume/section-can-education";
import SectionCanAttachment from "../sections/resume/section-can-attachment";
import SectionCanEmployment from "../sections/resume/section-can-employment";
import SectionCanPersonalDetail from "../sections/resume/section-can-personal";
import { fetchDashboard } from "../../../../reducers/candidate/CanDashboardSlice";
import SectionCanProfileSummary from "../sections/resume/section-can-profile-summary";
import SectionCanDesiredProfile from "../sections/resume/section-can-desired-profile";
import SectionCanAccomplishments from "../sections/resume/section-can-accomplishments";
import SectionCanResumeHeadline from "../sections/resume/section-can-resume-headline";
import { fetchCanResumeManager } from "../../../../reducers/candidate/CanResumeManagerSlice";

function CanMyResumePage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.CanDashboard.loading);
  const canAds = useSelector((state) => state.CanDashboard.canAds);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const CanResumeManager = useSelector((state) => state.CanResumeManager);

  console.log(CanResumeManager);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
    dispatch(fetchCanResumeManager(userUUID));
  }, [dispatch]);

  return (
    <>
      {CanResumeManager?.loading === true ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          {" "}
          <div className="wt-admin-right-page-header clearfix">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
                <h2>Resume Manager</h2>
                <div className="breadcrumbs">
                  <a>Home</a>
                  <a>Dashboard</a>
                  <span>Resume Manager</span>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
                <NavLink to={canAds?.Resume_Header_Link} target="_blank">
                  <img
                    src={canAds?.Resume_Header}
                    alt="Banner Image"
                    className="img-fluid"
                  />
                </NavLink>
              </div>
            </div>
          </div>
          {CanResumeManager?.CanResumeManager?.lenth > 0 ? (
            <>
              {" "}
              <div className="twm-right-section-panel site-bg-gray">
                {/*Resume Headline*/}
                <div className="panel panel-default mb-3">
                  <SectionCanResumeHeadline />
                </div>
                {/*Key Skills*/}
                <div className="panel panel-default mb-3">
                  <SectionCanKeySkills />
                </div>
                {/*Employment*/}
                <div className="panel panel-default mb-3">
                  <SectionCanEmployment />
                </div>
                {/*Education*/}
                <div className="panel panel-default mb-3">
                  <SectionCanEducation />
                </div>
                {/*IT Skills*/}
                <div className="panel panel-default mb-3">
                  <SectionCanITSkills />
                </div>
                {/*Project*/}
                <div className="panel panel-default mb-3">
                  <SectionCanProjects />
                </div>
                {/*Desired Career Profile*/}
                <div className="panel panel-default mb-3">
                  <SectionCanDesiredProfile />
                </div>
                {/*Personal Details*/}
                <div className="panel panel-default mb-3">
                  <SectionCanPersonalDetail />
                </div>
                {/*Attach Resume*/}
                <div className="panel panel-default mb-3">
                  <SectionCanAttachment />
                </div>
                {/*Accomplishments*/}
                <div className="panel panel-default mb-3">
                  <SectionCanAccomplishments />
                </div>
                {/*Profile Summary*/}
                <div className="panel panel-default mb-3">
                  <SectionCanProfileSummary />
                </div>
              </div>
            </>
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Index />
              </div>
            </>
          )}
          {/*Headline Display End*/}
          <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mt-5">
            <NavLink to={canAds?.Resume_Footer_Link} target="_blank">
              <img
                src={canAds?.Resume_Footer}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </>
      )}
    </>
  );
}

export default CanMyResumePage;
