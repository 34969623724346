import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import JobZImage from "../../../common/jobz-img";
import { loadScript } from "../../../../globals/constants";
import { fetchDashboard } from "../../../../reducers/employer/EmpDashboardSlice";
import { empRoute, employer } from "../../../../globals/route-names";

function EmpHeaderSection(props) {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const [sortedData, setSortedData] = useState([]);
  const empMessages = useSelector((state) => state.EmpDashboard.empMessages);

  const empNotifications = useSelector(
    (state) => state.EmpDashboard.empNotifications
  );

  const formatTimeDifference = (firebaseTimestamp) => {
    const messageDate = new Date(firebaseTimestamp.seconds * 1000);
    const currentDate = new Date();
    const timeDifference = Math.abs(currentDate - messageDate);

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return days === 1 ? "1 day ago" : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
    } else {
      return seconds === 1 ? "1 second ago" : `${seconds} seconds ago`;
    }
  };

  useEffect(() => {
    if (empMessages?.MsgList) {
      const data = Object.entries(empMessages.MsgList).map(([key, value]) => ({
        Id: key,
        Name: value.Name,
        Date: value.TimeStamp,
        Msg: value.Msg,
        TimeDifference: formatTimeDifference(value.TimeStamp),
      }));

      const sorted = data.sort((a, b) => {
        const dateA = new Date(a.Date.seconds * 1000);
        const dateB = new Date(b.Date.seconds * 1000);
        return dateB - dateA;
      });

      setSortedData(sorted);
    }
  }, [empMessages]);

  var Notification = [];
  if (empNotifications?.NotiList) {
    Object.entries(empNotifications.NotiList).forEach(([key, value]) => {
      Notification.push(
        Object.assign({
          Id: key,
          Msg: value.Msg,
          Priority: value.Priority,
        })
      );
    });
  }
  const NotificationData = Notification.sort((a, b) => a.Priority - b.Priority);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard());
  }, [dispatch]);
  return (
    <>
      <header id="header-admin-wrap" className="header-admin-fixed">
        {/* Header Start */}
        <div id="header-admin" className={props.sidebarActive ? "" : "active"}>
          <div className="container">
            <div className="header-left">
              <div className="nav-btn-wrap">
                <a
                  className="nav-btn-admin"
                  id="sidebarCollapse"
                  onClick={props.onClick}
                >
                  <span className="fa fa-angle-left" />
                </a>
              </div>
            </div>
            {/* Left Side Content End */}
            {/* Right Side Content */}
            <div className="header-right">
              <ul className="header-widget-wrap">
                {/*Message*/}
                <li className="header-widget dashboard-message-dropdown">
                  <div className="dropdown">
                    <a
                      href="#"
                      className="dropdown-toggle jobzilla-admin-messange"
                      id="ID-MSG_dropdown"
                      data-bs-toggle="dropdown"
                    >
                      <i className="far fa-envelope" />
                      {empMessages?.UnreadMsg > 0 && (
                        <span className="notification-animate">
                          {empMessages?.UnreadMsg}
                        </span>
                      )}
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="ID-MSG_dropdown"
                    >
                      <div className="message-list dashboard-widget-scroll">
                        <ul>
                          {sortedData?.map((item) => {
                            return (
                              <>
                                <li className="clearfix">
                                  <span className="msg-avtar">
                                    <img
                                      src="https://c2m-edutech.com/demo/person4.jpg"
                                      alt=""
                                    />
                                  </span>
                                  <div className="msg-texting">
                                    <strong>{item.Name}</strong>
                                    <small className="msg-time">
                                      <span className="far fa-clock p-r-5" />
                                      {item.TimeDifference}
                                    </small>
                                    <p>{item.Msg}</p>
                                  </div>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                        <div className="message-view-all">
                          <NavLink to={empRoute(employer.MESSAGES1)}>
                            View All
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                {/*Notification*/}
                <li className="header-widget dashboard-noti-dropdown">
                  <div className="dropdown">
                    <a
                      href="#"
                      className="dropdown-toggle jobzilla-admin-notification"
                      id="ID-NOTI_dropdown"
                      data-bs-toggle="dropdown"
                    >
                      <i className="far fa-bell" />
                      {empNotifications?.UnreadNoti > 0 && (
                        <span className="notification-animate">
                          {empNotifications?.UnreadNoti}
                        </span>
                      )}
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="ID-NOTI_dropdown"
                    >
                      <div className="dashboard-widgets-header">
                        You have 7 notifications
                      </div>
                      <div className="noti-list dashboard-widget-scroll">
                        <ul>
                          {NotificationData?.map((item, index) => {
                            return (
                              <>
                                <li key={index}>
                                  <a href="#">
                                    <span className="noti-icon">
                                      <i className="far fa-bell" />
                                    </span>
                                    <span className="noti-texting">
                                      {item.Msg}
                                    </span>
                                  </a>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                        <div className="noti-view-all">
                          <NavLink to={empRoute(employer.NOTIFICATIONS)}>
                            View All
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                {/*Account*/}
                <li className="header-widget">
                  <div className="dashboard-user-section">
                    <div className="listing-user">
                      <div className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          id="ID-ACCOUNT_dropdown"
                          data-bs-toggle="dropdown"
                        >
                          <div className="user-name text-black">
                            <span>
                              <img
                                src="https://c2m-edutech.com/demo/c2m.png"
                                alt=""
                              />
                            </span>
                            C2M
                          </div>
                        </a>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="ID-ACCOUNT_dropdown"
                        >
                          <ul>
                            <li>
                              <NavLink to={empRoute(employer.PROFILE)}>
                                <i className="fa fa-user" /> Profile
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to={empRoute(employer.UPDATE_PASSWORD)}>
                                <i className="fa fa-fingerprint" /> Update
                                Password
                              </NavLink>
                            </li>
                            <li>
                              <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#logout-dash-profile"
                              >
                                <i className="fa fa-share-square" /> Logout
                              </a>
                            </li>
                            <hr />
                            <li>
                              <NavLink to={empRoute(employer.CONTACT)}>
                                <i className="fas fa-envelope" /> Contact
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            {/* Right Side Content End */}
          </div>
        </div>
        {/* Header End */}
      </header>
    </>
  );
}

export default EmpHeaderSection;
