import { NavLink } from "react-router-dom";
import moment from "moment";
import JobZImage from "../../../../common/jobz-img";
import { publicUser } from "../../../../../globals/route-names";
import { loadScript, publicUrlFor } from "../../../../../globals/constants";
import { fetchBlogs } from "../../../../../reducers/public-user/blogSlice";
import { fetchFeaturedJobs } from "../../../../../reducers/public-user/featureJobsSlice";
import { useEffect } from "react";
import CountUp from "react-countup";
import { useSelector, useDispatch } from "react-redux";

function Home2Page() {
  const dispatch = useDispatch();
  useEffect(() => {
    loadScript("js/custom.js");
  });

  const featuredJobs = useSelector((state) => state.featuredJobs.featuredJobs);

  console.log(featuredJobs);

  const blogs = useSelector((state) => state.blogList.blogs);

  const firstFourPosts = blogs.slice(0, 3);
  const updatedItems = firstFourPosts?.map((item) => {
    const milliseconds = item.Date.seconds * 1000;
    const totalMilliseconds = milliseconds + item.Date.nanoseconds / 1000000;
    const date = new Date(totalMilliseconds);
    const newDate = moment(date).format("MMM Do YYYY");

    return {
      ...item,
      formattedDate: newDate,
    };
  });

  const updatedFeatureJobsData = featuredJobs?.map((item) => {
    const milliseconds = item.Date.seconds * 1000;
    const totalMilliseconds = milliseconds + item.Date.nanoseconds / 1000000;
    const date = new Date(totalMilliseconds);
    const newDate = moment(date).format("MMM Do YYYY");

    return {
      ...item,
      formattedDate: newDate,
    };
  });

  console.log(updatedFeatureJobsData);

  useEffect(() => {
    dispatch(fetchBlogs());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchFeaturedJobs());
  }, [dispatch]);

  return (
    <>
      <div
        className="twm-home2-banner-section site-bg-gray bg-cover"
        style={{
          backgroundImage: `url(${publicUrlFor(
            "images/lacnar-img/banner-bg-3.png"
          )})`,
        }}
      >
        <div className="row">
          {/*Left Section*/}
          <div className="col-xl-6 col-lg-6 col-md-12">
            <div className="twm-bnr-left-section">
              <div className="twm-bnr-title-small">
                LacnaR – Path to Triumph
              </div>
              <div className="twm-bnr-title-large">
                Bridging{" "}
                <span className="site-text-primary">
                  Collaborative Health Horizons
                </span>
              </div>
              <div className="twm-bnr-discription">
                Uniting on a single, comprehensive platform for streamlined
                healthcare access and delivery.
              </div>
              <NavLink to={publicUser.pages.LOGIN} className="site-button">
                Get Started
              </NavLink>
            </div>
          </div>
          {/*right Section*/}
          <div className="col-xl-6 col-lg-6 col-md-12 twm-bnr-right-section">
            <div className="twm-bnr2-right-content">
              <div className="twm-img-bg-circle-area2">
                <div className="twm-outline-ring-wrap">
                  <div className="twm-outline-ring-dott-wrap">
                    <span className="outline-dot-1" />
                    <span className="outline-dot-2" />
                    <span className="outline-dot-3" />
                    {/*Samll Ring Left*/}
                    <div className="twm-small-ring-l scale-up-center" />
                  </div>
                </div>
              </div>
              <div className="twm-home-2-bnr-images">
                <div className="bnr-image-1">
                  <JobZImage src="images/lacnar-img/banner-img-1.png" alt="" />
                </div>
                <div className="bnr-image-2">
                  <JobZImage
                    src="images/lacnar-img/banner-right-3.png"
                    alt=""
                  />
                </div>
                x1
                <div className="twm-small-ring-2 scale-up-center" />
              </div>
              {/*icon-block-1*/}
              {/*icon-block-1*/}
              {/* <div className="twm-bnr-blocks twm-bnr-blocks-position-1">
                                <div className="twm-icon">
                                    <JobZImage src="images/main-slider/slider2/icon-1.png" alt="" />
                                </div>
                                <div className="twm-content">
                                    <div className="tw-count-number text-clr-sky">
                                        <span className="counter">
                                            <CountUp end={12} duration={10} />    
                                        </span>K+
                                    </div>
                                    <p className="icon-content-info">Companies Jobs</p>
                                </div>
                            </div> */}
              {/*icon-block-2*/}
              {/* <div className="twm-bnr-blocks twm-bnr-blocks-position-2">
                                <div className="twm-icon pink">
                                    <JobZImage src="images/main-slider/slider2/icon-2.png" alt="" />
                                </div>
                                <div className="twm-content">
                                    <div className="tw-count-number text-clr-pink">
                                        <span className="counter">
                                            <CountUp end={98} duration={10} />
                                        </span> +
                                    </div>
                                    <p className="icon-content-info">Job For Countries </p>
                                </div>
                            </div> */}
              {/*icon-block-3*/}
              {/* <div className="twm-bnr-blocks-3 twm-bnr-blocks-position-3">
                                <div className="twm-pics">
                                    <span><JobZImage src="images/main-slider/slider2/user/u-1.jpg" alt="" /></span>
                                    <span><JobZImage src="images/main-slider/slider2/user/u-2.jpg" alt="" /></span>
                                    <span><JobZImage src="images/main-slider/slider2/user/u-3.jpg" alt="" /></span>
                                    <span><JobZImage src="images/main-slider/slider2/user/u-4.jpg" alt="" /></span>
                                    <span><JobZImage src="images/main-slider/slider2/user/u-5.jpg" alt="" /></span>
                                    <span><JobZImage src="images/main-slider/slider2/user/u-6.jpg" alt="" /></span>
                                </div>
                                <div className="twm-content">
                                    <div className="tw-count-number text-clr-green">
                                        <span className="counter">
                                            <CountUp end={3} duration={10} />
                                        </span>K+
                                    </div>
                                    <p className="icon-content-info">Jobs Done</p>
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
      {/*Search Bar*/}
      <div className="twm-search-bar-2-wrap">
        <div className="container">
          <div className="twm-search-bar-2-inner">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="twm-bnr-search-bar">
                  <form>
                    <div className="row">
                      {/*Title*/}
                      <div className="form-group col-lg-3 col-md-6">
                        <label>What</label>
                        <select
                          className="wt-search-bar-select selectpicker"
                          data-live-search="true"
                          title=""
                          id="j-Job_Title"
                          data-bv-field="size"
                        >
                          <option disabled value="">
                            Select Category
                          </option>
                          <option selected>Job Title</option>
                          <option>Pharmacist</option>
                          <option>Cardiologist</option>
                          <option>Physician</option>
                        </select>
                      </div>
                      {/*All Category*/}
                      <div className="form-group col-lg-3 col-md-6">
                        <label>Type</label>
                        <select
                          className="wt-search-bar-select selectpicker"
                          data-live-search="true"
                          title=""
                          id="j-All_Category"
                          data-bv-field="size"
                        >
                          <option disabled value="">
                            Select Category
                          </option>
                          <option selected>All Category</option>
                          <option>Pharmacist</option>
                          <option>Cardiologist</option>
                          <option>Physician</option>
                        </select>
                      </div>
                      {/*Location*/}
                      <div className="form-group col-lg-3 col-md-6">
                        <label>Location</label>
                        <div className="twm-inputicon-box">
                          <input
                            name="username"
                            type="text"
                            required
                            className="form-control"
                            placeholder="Location"
                          />
                          <i className="twm-input-icon fas fa-map-marker-alt" />
                        </div>
                      </div>
                      {/*Find job btn*/}
                      <div className="form-group col-lg-3 col-md-6">
                        <button type="button" className="site-button">
                          Find Job
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* <div className="col-lg-3 col-md-12">
                <div className="twm-trusted-by-wrap">
                  <div className="twm-trusted-by-title">Trusted By :</div>
                  <div className="owl-carousel trusted-logo owl-btn-vertical-center">
                    <div className="item">
                      <div className="twm-trusted-logo">
                        <NavLink to={publicUser.employer.LIST}>
                          <JobZImage src="images/trusted/logo-1.png" alt="" />
                        </NavLink>
                      </div>
                    </div>
                    <div className="item">
                      <div className="twm-trusted-logo">
                        <NavLink to={publicUser.employer.LIST}>
                          <JobZImage src="images/trusted/logo-2.png" alt="" />
                        </NavLink>
                      </div>
                    </div>
                    <div className="item">
                      <div className="twm-trusted-logo">
                        <NavLink to={publicUser.employer.LIST}>
                          <JobZImage src="images/trusted/logo-3.png" alt="" />
                        </NavLink>
                      </div>
                    </div>
                    <div className="item">
                      <div className="twm-trusted-logo">
                        <NavLink to={publicUser.employer.LIST}>
                          <JobZImage src="images/trusted/logo-1.png" alt="" />
                        </NavLink>
                      </div>
                    </div>
                    <div className="item">
                      <div className="twm-trusted-logo">
                        <NavLink to={publicUser.employer.LIST}>
                          <JobZImage src="images/trusted/logo-2.png" alt="" />
                        </NavLink>
                      </div>
                    </div>
                    <div className="item">
                      <div className="twm-trusted-logo">
                        <NavLink to={publicUser.employer.LIST}>
                          <JobZImage src="images/trusted/logo-3.png" alt="" />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="twm-bnr-popular-search">
              <span className="twm-title">Popular Searches:</span>
              <NavLink to={publicUser.jobs.LIST}>
                Otorhinolaryngologist{" "}
              </NavLink>{" "}
              ,<NavLink to={publicUser.jobs.LIST}>Ophthalmologist </NavLink> ,
              <NavLink to={publicUser.jobs.LIST}>Psychiatry </NavLink> ,
              <NavLink to={publicUser.jobs.LIST}>General Surgery</NavLink> ,
              <NavLink to={publicUser.jobs.LIST}>Anaesthesiologist </NavLink>...
            </div>
          </div>
        </div>
      </div>
      {/* How It Work START */}
      <div className="section-full site-bg-primary twm-how-it-work-1-area">
        <div className="container">
          <div className="section-content">
            <div className="twm-how-it-work-1-content">
              <div className="row">
                <div className="col-xl-5 col-lg-12 col-md-12">
                  <div className="twm-how-it-work-1-left">
                    <div className="twm-how-it-work-1-section">
                      {/* title="" START*/}
                      <div className="section-head left wt-small-separator-outer">
                        <div className="wt-small-separator">
                          <div>How Lacnar Works</div>
                        </div>
                        <h2>Follow our steps we will help you.</h2>
                      </div>
                      {/* title="" END*/}
                      <div className="twm-step-section-4">
                        <ul>
                          <li>
                            <div className="twm-step-count bg-clr-sky-light">
                              01
                            </div>
                            <div className="twm-step-content">
                              <h4 className="twm-title">Career Advancement</h4>
                              <p>
                                Discover and apply for job opportunities that
                                align with your skills and career goals,
                                enhancing your professional growth .
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="twm-step-count bg-clr-yellow-light">
                              02
                            </div>
                            <div className="twm-step-content">
                              <h4 className="twm-title">Efficient Staffing</h4>
                              <p>
                                Efficiently recruit, manage, and allocate
                                qualified health professionals across
                                institutions to ensure optimal service delivery
                                .
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="twm-step-count bg-clr-pink-light">
                              03
                            </div>
                            <div className="twm-step-content">
                              <h4 className="twm-title">
                                Industry Connections
                              </h4>
                              <p>
                                Connect students with internship and placement
                                opportunities in leading health organizations to
                                support their career development .
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="twm-step-count bg-clr-green-light">
                              04
                            </div>
                            <div className="twm-step-content">
                              <h4 className="twm-title">
                                Direct Market Access
                              </h4>
                              <p>
                                Engage directly with hospitals and health
                                institutions to showcase and supply your medical
                                products and devices .
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-12 col-md-12">
                  <div className="twm-how-it-right-section">
                    <div className="twm-media">
                      <div className="twm-bg-circle">
                        <JobZImage
                          src="images/home-4/how-it-work/bg-circle-large.png"
                          alt=""
                        />
                      </div>
                      <div
                        className="twm-block-left anm"
                        data-speed-x={-4}
                        data-speed-scale={-25}
                      >
                        <JobZImage
                          src="images/home-4/how-it-work/block-left.png"
                          alt=""
                        />
                      </div>
                      <div
                        className="twm-block-right anm"
                        data-speed-x={-4}
                        data-speed-scale={-25}
                      >
                        <JobZImage
                          src="images/home-4/how-it-work/block-right.png"
                          alt=""
                        />
                      </div>
                      <div
                        className="twm-main-bg anm"
                        data-wow-delay="1000ms"
                        data-speed-x={2}
                        data-speed-y={2}
                      >
                        <JobZImage
                          src="images/lacnar-img/how-it-work-2.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* How It Work END */}

      {/* FOR EMPLOYEE START */}
      <div className="section-full p-t120 p-b120 twm-for-employee-area site-bg-white">
        <div className="container">
          <div className="section-content">
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <div className="twm-explore-media-wrap">
                  <div className="twm-media">
                    <JobZImage src="images/lacnar-img/candidate.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="twm-explore-content-outer-3">
                  <div className="twm-explore-content-3">
                    <div className="twm-title-small">
                      For Healthcare professionals
                    </div>
                    <div className="twm-title-large">
                      <h2>Access exclusive opportunities</h2>
                      <p>
                        Discover lucrative job openings, freelance projects, and
                        professional development courses
                      </p>
                    </div>

                    <div className="twm-upload-file">
                      {/* <button type="button" className="site-button">
                        Upload Your Resume <i className="feather-upload" />
                      </button> */}
                      <NavLink
                        to={publicUser.pages.LOGIN}
                        className="site-button"
                      >
                        Get Started
                      </NavLink>
                    </div>
                  </div>
                  <div className="twm-l-line-1" />
                  <div className="twm-l-line-2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* FOR EMPLOYEE END */}

      {/* EXPLORE NEW LIFE START */}
      <div className="section-full p-t120 p-b120 site-bg-white twm-explore-area2">
        <div className="container">
          <div className="section-content">
            <div className="twm-explore-content-2">
              <div className="row">
                <div className="col-lg-8 col-md-12">
                  <div className="twm-explore-content-outer2">
                    <div className="twm-explore-top-section">
                      <div className="twm-title-small">For Employers</div>
                      <div className="twm-title-large">
                        <h2>Optimize your operations :</h2>
                        <p>
                          Discover innovative solutions and services to improve
                          patient care and efficiency
                        </p>
                      </div>
                      <div className="twm-read-more">
                        <NavLink
                          to={publicUser.pages.ABOUT}
                          className="site-button"
                        >
                          Read More
                        </NavLink>
                      </div>
                    </div>
                    <div className="twm-explore-bottom-section">
                      <div className="row">
                        {/*block 1*/}
                        {/* <div className="col-lg-4 col-md-6">
                                                    <div className="counter-outer-two">
                                                        <div className="icon-content">
                                                            <div className="tw-count-number text-clr-yellow-2">
                                                                <span className="counter">
                                                                    <CountUp end={5} duration={10} />
                                                                </span>M+</div>
                                                            <p className="icon-content-info">Million daily active users</p>
                                                        </div>
                                                    </div>
                                                </div> */}
                        {/*block 2*/}
                        {/* <div className="col-lg-4 col-md-6">
                                                    <div className="counter-outer-two">
                                                        <div className="icon-content">
                                                            <div className="tw-count-number text-clr-green">
                                                                <span className="counter">
                                                                    <CountUp end={9} duration={10} />
                                                                </span>K+</div>
                                                            <p className="icon-content-info">Open job positions</p>
                                                        </div>
                                                    </div>
                                                </div> */}
                        {/*block 3*/}
                        {/* <div className="col-lg-4 col-md-12">
                                                    <div className="counter-outer-two">
                                                        <div className="icon-content">
                                                            <div className="tw-count-number text-clr-pink">
                                                                <span className="counter">
                                                                    <CountUp end={2} duration={10} />
                                                                </span>M+</div>
                                                            <p className="icon-content-info">Million stories shared</p>
                                                        </div>
                                                    </div>
                                                </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12">
                  <div className="twm-explore-media-wrap2">
                    <div className="twm-media">
                      <JobZImage src="images/lacnar-img/employer.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* EXPLORE NEW LIFE END */}

      {/* HOW TO GET YOUR JOB SECTION START */}
      <div className="section-full p-t120 p-b90 site-bg-gray twm-how-t-get-wrap7">
        <div className="container">
          <div className="twm-how-t-get-section">
            <div className="row">
              <div className="col-xl-5 col-lg-5 col-md-12">
                <div className="twm-how-t-get-section-left">
                  <div className="section-head left wt-small-separator-outer">
                    <div className="wt-small-separator site-text-primary">
                      <div>For Medical Service providers</div>
                    </div>
                    <h2 className="wt-title">Reach Wide Market</h2>
                    <p>
                      Leverage Lacnar's network for seamless product delivery
                      and management.
                    </p>
                  </div>
                  {/* <div className="twm-how-t-get-bottom">
                    <NavLink
                      to={publicUser.pages.ABOUT}
                      className="site-button"
                    >
                      Edit Profile
                    </NavLink>
                    <div className="twm-left-icon-bx">
                      <div className="twm-left-icon-media site-bg-primary">
                        <i className="flaticon-bell site-text-white" />
                      </div>
                      <div className="twm-left-icon-content">
                        <h4 className="icon-title">New Interview</h4>
                        <p>You has new interview today</p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="col-xl-7 col-lg-7 col-md-12">
                <div className="twm-how-t-get-section-right">
                  <div className="twm-media">
                    <JobZImage
                      src="images/lacnar-img/service-provider.png"
                      alt="#"
                    />
                  </div>
                  <div className="twm-left-img-bx bounce">
                    <div className="twm-left-img-media">
                      <JobZImage src="images/home-7/img-bx/pic1.jpg" alt="#" />
                    </div>
                    <div className="twm-left-img-content">
                      <h4 className="icon-title">Complete your profile</h4>
                      <p>95% Completed</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-full p-t120 p-b90 site-bg-white twm-hpage-6-featured-outer">
        {/* title="" START*/}
        <div className="section-head center wt-small-separator-outer">
          <div className="wt-small-separator site-text-primary">
            <div>Jobs Category</div>
          </div>
          <h2 className="wt-title">Featured Jobs</h2>
        </div>
        {/* title="" END*/}
        <div className="twm-hpage-6-featured-area">
          <div className="twm-hpage-6-featured-bg-warp">
            <div className="twm-media">
              <JobZImage src="images/lacnar-img/featured.png" alt="#" />
            </div>
          </div>
          <div className="container">
            <div className="twm-hpage-6-featured-content-warp m-b30">
              <div className="row">
                <div className="col-lg-8 col-md-12">
                  <div className="row">
                    {updatedFeatureJobsData?.slice(0, 2).map((item, index) => {
                      return (
                        <>
                          <div className="col-lg-6 col-md-6 m-b30">
                            <div className="hpage-6-featured-block">
                              <div className="inner-content">
                                <div className="top-content">
                                  <span className="job-time">{item.Type}</span>
                                  <span className="job-post-time">
                                    {item.formattedDate}
                                  </span>
                                </div>
                                <div className="mid-content">
                                  <div className="company-logo">
                                    <JobZImage
                                      src="images/home-6/featured-jobs/instagram.png"
                                      alt="#"
                                    />
                                  </div>
                                  <div className="company-info">
                                    <NavLink
                                      to={item.Link}
                                      className="company-name"
                                    >
                                      {item.CompanyTitle}
                                    </NavLink>
                                    <p className="company-address">
                                      {item.Location}
                                    </p>
                                  </div>
                                </div>
                                <div className="bottom-content">
                                  <h4 className="job-name-title">
                                    {item.Title}
                                  </h4>
                                  {/* <div className="job-payment">
                                    <span>{item.Salary}</span>/ Month
                                  </div> */}
                                </div>
                                <div className="aply-btn-area">
                                  <NavLink to={item.Link} className="aplybtn">
                                    <i className="fa fa-check" />
                                  </NavLink>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="row">
                {updatedFeatureJobsData?.slice(2, 5).map((item, index) => {
                  return (
                    <>
                      <div key={index} className="col-lg-4 col-md-6 m-b30">
                        <div className="hpage-6-featured-block">
                          <div className="inner-content">
                            <div className="top-content">
                              <span className="job-time">{item.Type}</span>
                              <span className="job-post-time">
                                {item.formattedDate}
                              </span>
                            </div>
                            <div className="mid-content">
                              <div className="company-logo">
                                <JobZImage
                                  src="images/home-6/featured-jobs/pinterest.png"
                                  alt="#"
                                />
                              </div>
                              <div className="company-info">
                                <NavLink
                                  to={item.Link}
                                  className="company-name"
                                >
                                  {" "}
                                  {item.CompanyTitle}
                                </NavLink>
                                <p className="company-address">
                                  {item.Location}
                                </p>
                              </div>
                            </div>
                            <div className="bottom-content">
                              <h4 className="job-name-title"> {item.Title}</h4>
                              {/* <div className="job-payment">
                                <span>{item.Salary}</span>/ Month
                              </div> */}
                            </div>
                            <div className="aply-btn-area">
                              <NavLink to={item.Link} className="aplybtn">
                                <i className="fa fa-check" />
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="text-center job-categories-btn">
                <NavLink to={publicUser.jobs.LIST} className=" site-button">
                  Explore more Jobs
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Featured Jobs SECTION END */}
      {/* OUR BLOG START */}
      <div
        className="section-full p-t120 p-b90 site-bg-gray bg-cover overlay-wraper"
        style={{
          backgroundImage: `url(₹{publicUrlFor("images/lacnar-img/footer.jpg")})`,
        }}
      >
        <div className="overlay-main site-bg-primary opacity-01" />
        <div className="container">
          {/* title="" START*/}
          <div className="section-head center wt-small-separator-outer">
            <div className="wt-small-separator site-text-primary">
              <div>Our Blogs</div>
            </div>
            <h2 className="wt-title ">Latest Article</h2>
          </div>
          {/* title="" END*/}
          <div className="section-content">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-5 col-md-12 m-b30">
                {/*Block one*/}
                <div className="blog-post twm-blog-post-2-outer">
                  <div className="wt-post-media">
                    <NavLink to={publicUser.blog.DETAIL}>
                      <JobZImage src="images/lacnar-img/blog-1.png" alt="" />
                    </NavLink>
                  </div>
                  <div className="wt-post-info">
                    <div className="wt-post-meta ">
                      <ul>
                        <li className="post-date">April 05, 2023</li>
                      </ul>
                    </div>
                    <div className="wt-post-title ">
                      <h4 className="post-title">
                        <NavLink to={publicUser.blog.DETAIL}>
                          How to convince recruiters and get your dream job. Get
                          behind the wheel!
                        </NavLink>
                      </h4>
                    </div>
                    <div className="wt-post-readmore ">
                      <NavLink
                        to={publicUser.blog.DETAIL}
                        className="site-button-link site-text-secondry"
                      >
                        Read More
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="twm-blog-post-wrap-right">
                  {updatedItems?.map((item) => (
                    <div className="blog-post twm-blog-post-1-outer shadow-none  m-b30">
                      <div className="wt-post-info">
                        <div className="wt-post-meta ">
                          <ul>
                            <li className="post-date">{item.formattedDate}</li>
                            <li className="post-author">
                              By &nbsp;
                              <NavLink to={publicUser.candidate.DETAIL1}>
                                {item.Author}
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                        <div className="wt-post-title ">
                          <h4 className="post-title">
                            <NavLink to={publicUser.blog.DETAIL}>
                              {item.Title}
                            </NavLink>
                          </h4>
                        </div>
                        <div className="wt-post-text-qw">
                          <p>{item.Description}</p>
                        </div>
                        <div className="wt-post-readmore ">
                          <NavLink
                            to={publicUser.blog.DETAIL}
                            className="site-button-link site-text-primary"
                          >
                            Read More
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* OUR BLOG END */}
      </div>
    </>
  );
}

export default Home2Page;
