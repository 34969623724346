import React, { useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Index from "../../../candidate/common/no-data/Index";
import { loadScript } from "../../../../../globals/constants";
import { fetchDashboard } from "../../../../../reducers/employer/EmpDashboardSlice";
import { fetchEmpManageJob } from "../../../../../reducers/employer/EmpManageJobSlice";

function EmpManageJobPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const empAds = useSelector((state) => state.EmpDashboard.empAds);
  const manageJobsList = useSelector((state) => state.EmpManageJob);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);

  console.log(manageJobsList);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
    dispatch(fetchEmpManageJob(userUUID));
  }, [dispatch]);

  return (
    <>
      {manageJobsList?.loading === true ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          <div className="wt-admin-right-page-header clearfix">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
                <h2>Manage Jobs</h2>
                <div className="breadcrumbs">
                  <a>Home</a>
                  <a>Dashboard</a>
                  <span>My Job Listing</span>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
                <NavLink to={empAds?.ManageJobs_Header_Link} target="_blank">
                  <img
                    src={empAds?.ManageJobs_Header}
                    alt="Banner Image"
                    className="img-fluid"
                  />
                </NavLink>
              </div>
            </div>
          </div>
          {manageJobsList?.EmpManageJob?.length > 0 ? (
            <div className="panel panel-default">
              <div className="panel-heading wt-panel-heading p-a20">
                <h4 className="panel-tittle m-a0">
                  <i className="fa fa-suitcase" /> Job Details
                </h4>
              </div>
              <div className="panel-body wt-panel-body p-a20 m-b30 ">
                <div className="twm-D_table p-a20 table-responsive">
                  <table
                    id="jobs_bookmark_table"
                    className="table table-bordered twm-bookmark-list-wrap"
                  >
                    <thead>
                      <tr>
                        <th>Sl.No</th>
                        <th>Posted Jobs</th>
                        <th>Category</th>
                        <th>Applications</th>
                        <th>Created &amp; Expired</th>
                        <th>Job Types</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {manageJobsList?.EmpManageJob?.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>
                            <div className="twm-bookmark-list">
                              <div className="twm-media">
                                <div className="twm-media-pic">
                                  <img src={item.ImgUrl} alt="" />
                                </div>
                              </div>
                              <div className="twm-mid-content">
                                <a href="#" className="twm-job-title">
                                  <h4>{item.Title}</h4>
                                  <p className="twm-bookmark-address">
                                    <i className="feather-map-pin" />
                                    {item.Address}
                                  </p>
                                </a>
                              </div>
                            </div>
                          </td>
                          <td>{item.Category}</td>
                          <td>
                            <a href="#" className="site-text-primary">
                              {item.Applicants}
                            </a>
                          </td>
                          <td>
                            <div>{item.CreatedDate}</div>
                            <div>{item.ExpiredDate}</div>
                          </td>
                          <td>
                            <div className="twm-jobs-category">
                              <span className="twm-bg-brown">
                                {item.JobType}
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="twm-table-controls">
                              <ul className="twm-DT-controls-icon list-unstyled">
                                <li>
                                  <button
                                    title="View profile"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="fa fa-eye" />
                                  </button>
                                </li>
                                <li>
                                  <button
                                    title="Delete"
                                    data-bs-toggle="modal"
                                    data-bs-target="#logout-delete-job"
                                  >
                                    <span className="far fa-trash-alt" />
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>Sl.No</th>
                        <th>Posted Jobs</th>
                        <th>Category</th>
                        <th>Applications</th>
                        <th>Created &amp; Expired</th>
                        <th>Job Types</th>
                        <th>Action</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Index />
            </div>
          )}

          <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mt-4">
            <NavLink to={empAds?.ManageJobs_Footer_Link} target="_blank">
              <img
                src={empAds?.ManageJobs_Footer}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </>
      )}
    </>
  );
}
export default EmpManageJobPage;
