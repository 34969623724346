import { createSlice } from "@reduxjs/toolkit";
import { getDocs, collection } from "firebase/firestore";
import { firestore } from "../../config/fbConfig";

//Initial Value of Slice
const initialState = {
  EmpNotification: [
    {
      Id: "Primary-1",
      TransactionID: "12345",
      Name: "Swathi",
      Amount: "1500",
      Date: "28/01/2023",
      Status: "Completed",
    },
  ],
  loading: false,
  error: null,
};

//Action of Slice
const EmpNotificationSlice = createSlice({
  name: "EmpNotification",
  initialState,
  reducers: {
    setEmpNotificationStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setEmpNotificationSuccess: (state, action) => {
      state.loading = false;
      state.EmpNotification = action.payload;
    },
    setEmpNotificationFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

//Export of Slice
export const {
  setEmpNotificationStart,
  setEmpNotificationSuccess,
  setEmpNotificationFailure,
} = EmpNotificationSlice.actions;
export default EmpNotificationSlice.reducer;

export const fetchEmpNotification = (userUUID) => async (dispatch) => {
  try {
    dispatch(setEmpNotificationStart());
    const querySnapshot = await getDocs(
      collection(firestore, "Users", userUUID, "EmpNotification")
    );
    const EmpNotification = [];
    querySnapshot.forEach((doc) => {
      EmpNotification.push(doc.data());
    });
    dispatch(setEmpNotificationSuccess(EmpNotification));
  } catch (error) {
    dispatch(setEmpNotificationFailure(error.message));
  }
};
