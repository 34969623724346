import { useEffect } from "react";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import JobZImage from "../../../../../common/jobz-img";
import { publicUser } from "../../../../../../globals/route-names";
import { fetchFeaturedJobs } from "../../../../../../reducers/public-user/featureJobsSlice";

function SectionAvailableJobsGrid() {
  const dispatch = useDispatch();
  const featuredJobs = useSelector((state) => state.featuredJobs.featuredJobs);
  const firstFourPosts = featuredJobs.slice(0, 4);
  const updatedItems = firstFourPosts?.map((item) => {
    const milliseconds = item.Date.seconds * 1000;
    const totalMilliseconds = milliseconds + item.Date.nanoseconds / 1000000;
    const date = new Date(totalMilliseconds);
    const newDate = moment(date).format("MMM Do YYYY");

    return {
      ...item,
      formattedDate: newDate,
    };
  });

  console.log(featuredJobs, "sadfs");

  useEffect(() => {
    dispatch(fetchFeaturedJobs());
  }, [dispatch]);

  return (
    <>
      <h4 className="twm-s-title">Featured Jobs</h4>
      <div className="twm-jobs-list-wrap">
        <div className="row">
          {updatedItems?.map((item, index) => {
            console.log(item);
            return (
              <>
                <div key={index} className="col-lg-6 col-md-12 m-b30">
                  <div className="twm-jobs-grid-style1">
                    <div className="twm-media">
                      <JobZImage src={item.ImgUrl} alt="#" />
                    </div>
                    <span className="twm-job-post-duration">
                      {item.formattedDate}
                    </span>
                    <div className="twm-jobs-category green">
                      <span className="twm-bg-green">{item.Type}</span>
                    </div>
                    <div className="twm-mid-content">
                      <NavLink
                        to={publicUser.jobs.DETAIL1}
                        className="twm-job-title"
                      >
                        <h4> {item.CompanyTitle}</h4>
                      </NavLink>
                      <p className="twm-job-address">{item.Location}</p>
                      {/* <a
                        href="https://themeforest.net/user/thewebmax/portfolio"
                        className="twm-job-websites site-text-primary"
                      >
                        https://thewebmax.com
                      </a> */}
                    </div>
                    <div className="twm-right-content">
                      <div className="bottom-content">
                        <h4 className="job-name-title">{item.Title}</h4>
                        <div className="job-payment">
                          <span>{item.Salary}</span>/ Month
                        </div>
                      </div>
                      <NavLink
                        to={publicUser.jobs.DETAIL1}
                        className="twm-jobs-browse site-text-primary"
                      >
                        Browse Job
                      </NavLink>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default SectionAvailableJobsGrid;
