import { createSlice } from "@reduxjs/toolkit";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../config/fbConfig";

// Define initial state
const initialState = {
  featuredJobs: [],
  loading: false,
  error: null,
};

const featuredJobsSlice = createSlice({
  name: "featuredJobs",
  initialState,
  reducers: {
    setFeaturedJobsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setFeaturedJobsSuccess: (state, action) => {
      state.loading = false;
      state.featuredJobs = action.payload;
    },
    setFeaturedJobsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setFeaturedJobsStart,
  setFeaturedJobsSuccess,
  setFeaturedJobsFailure,
} = featuredJobsSlice.actions;

export default featuredJobsSlice.reducer;

export const fetchFeaturedJobs = () => async (dispatch) => {
  try {
    dispatch(setFeaturedJobsStart());
    const docRef = doc(firestore, "Public", "FeatureJobs");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const featuredJobs = docSnap.data();
      var Data = [];
      if (featuredJobs) {
        Object.entries(featuredJobs).forEach(([key, value]) => {
          Data.push(
            Object.assign({
              Id: key,
              CompanyTitle: value.CompanyTitle,
              Date: value.Date,
              ImgUrl: value.Logo,
              Title: value.JobTitle,
              Location: value.Location,
              Link: value.Link,
              Salary: value.Salary,
              Type: value.Type,
            })
          );
        });
      }
      dispatch(setFeaturedJobsSuccess(Data));
    } else {
      dispatch(setFeaturedJobsFailure("Document not found"));
    }
  } catch (error) {
    dispatch(setFeaturedJobsFailure(error.message));
  }
};
