import React from "react";
import { Grid, Button, IconButton, Divider } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Avatar from "@mui/material/Avatar";
import { deepOrange } from "@mui/material/colors";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

function EmpCart(props) {
  const classes = useStyles();
  const top100Films = [
    { label: "1 month" },
    { label: "2 months" },
    { label: "3 months" },
  ];

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} sm={12} md={7}>
        <h3 style={{ fontSize: "34px" }}>Your Cart</h3>
        <h5>lacnar is a trusted growth partner to millions of everyday</h5>
        <div className={classes.border}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} className={classes.parent}>
              <Avatar sx={{ bgcolor: deepOrange[500], width: 56, height: 56 }}>
                N
              </Avatar>
              <div>
                <h4>Web Hosting Economy</h4>
                <div style={{ marginTop: "1rem" }}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    options={top100Films}
                    sx={{ width: 250 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="1 month"
                        label="Months"
                      />
                    )}
                  />
                </div>
                <div style={{ marginTop: "10px" }}>
                  <p style={{ color: "#767676" }}>
                    Renews June 2027 for ₹16,164.00
                  </p>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              align="end"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div>
                <h5 style={{ color: "#09757A" }}>₹3,164.00</h5>
                <IconButton>
                  <DeleteOutlineIcon fontSize="small" />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={5} style={{ marginTop: "5.5rem" }}>
        <div className={classes.background}>
          <div style={{ marginBottom: "1rem" }}>
            <h3>Order Summary</h3>
            <h5 style={{ fontSize: "15px" }}>5 items</h5>
          </div>
          <Divider />
          <div
            style={{
              margin: "1rem 0rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4>Subtotal (INR)</h4>
            <h4 style={{ color: "#09757A" }}>₹21,528.00</h4>
          </div>
          <div>
            <Button fullWidth variant="contained">
              <h5 style={{ color: "white", textTransform: "capitalize" }}>
                I'm ready to pay
              </h5>
            </Button>
          </div>
        </div>

        <div className={classes.text}>
          <h6>
            <CheckCircleOutlineIcon fontSize="small" /> Satisfaction Guaranteed
          </h6>
        </div>

        <div>
          <h6>
            If you're not completely happy with your purchase, contact our
            Guides, 24/7/365, and we'll make it right.
          </h6>
        </div>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  border: {
    border: "1px solid #D6D6D6",
    padding: "1rem",
    borderRadius: "6px",
    marginTop: "1.3rem",
  },
  parent: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  background: {
    backgroundColor: "#F4F6F7",
    padding: "2rem",
    borderRadius: "6px",
  },
  text: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem",
    alignItems: "center",
  },
}));

export default EmpCart;
