import { useEffect, useState, useMemo } from "react";
import moment from "moment";
import SectionBlogsSidebar from "../../sections/blogs/sidebar/section-blogs-sidebar";
import { ThemeProvider, createTheme, Pagination } from "@mui/material";
import { loadScript } from "../../../../../globals/constants";
import { publicUser } from "../../../../../globals/route-names";
import JobZImage from "../../../../common/jobz-img";
import { fetchBlogs } from "../../../../../reducers/public-user/blogSlice";

import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

function BlogListPage() {
  const dispatch = useDispatch();
  const blogs = useSelector((state) => state.blogList.blogs);

  console.log(blogs);

  const itemsPerPage = 6;
  const totalPages = Math.ceil(blogs?.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, blogs?.length);
  const currentItems = blogs.slice(startIndex, endIndex);

  const blogData = currentItems?.map((item) => {
    const milliseconds = item.Date.seconds * 1000;
    const totalMilliseconds = milliseconds + item.Date.nanoseconds / 1000000;
    const date = new Date(totalMilliseconds);
    return {
      ...item,
      date: date,
      formattedDate: moment(date).format("MMM Do YYYY"), // Optional formatting
    };
  });
  blogData.sort((a, b) => b.date - a.date);

  useMemo(() => {
    window.scrollTo({ top: 0 });
  }, [currentPage]);

  useEffect(() => {
    dispatch(fetchBlogs());
  }, [dispatch]);

  return (
    <>
      <div className="section-full p-t120  p-b90 site-bg-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              {blogData?.map((item) => {
                return (
                  <div className="blog-post twm-blog-post-1-outer twm-blog-list-style">
                    <div className="wt-post-media">
                      <NavLink to={publicUser.blog.DETAIL}>
                        <JobZImage src="images/blog/latest/bg1.jpg" alt="" />
                      </NavLink>
                    </div>
                    <div className="wt-post-info">
                      <div className="wt-post-meta ">
                        <ul>
                          <li className="post-date">{item.formattedDate}</li>
                          <li className="post-author">
                            By{" "}
                            <NavLink to={publicUser.candidate.DETAIL1}>
                              {item.Author}
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                      <div className="wt-post-title ">
                        <h4 className="post-title">
                          <NavLink to={publicUser.blog.DETAIL}>
                            {item.Title}
                          </NavLink>
                        </h4>
                      </div>
                      <div className="wt-post-text ">
                        <p>{item.Description}</p>
                      </div>
                      <div className="wt-post-readmore ">
                        <NavLink
                          to={publicUser.blog.DETAIL}
                          className="site-button-link site-text-primary"
                        >
                          Read More
                        </NavLink>
                      </div>
                    </div>
                  </div>
                );
              })}
              <ThemeProvider theme={themes}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={(event, value) => setCurrentPage(value)}
                  shape="rounded"
                />
              </ThemeProvider>
            </div>
            <div className="col-lg-4 col-md-12 rightSidebar">
              <SectionBlogsSidebar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogListPage;
const themes = createTheme({
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          padding: "22px",
          fontWeight: "400",
          fontFamily: "Rubik",
          fontSize: "16px",
          lineHeight: "50px",
          height: "50px",
          borderRadius: "12px",
          "&.Mui-selected": {
            backgroundColor: "#046434",
            color: "white",
            "&:hover": {
              backgroundColor: "#046434",
              color: "white",
            },
          },
        },
      },
    },
  },
});
