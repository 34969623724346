import { createSlice } from "@reduxjs/toolkit";
import { getDocs, collection } from "firebase/firestore";
import { firestore } from "../../config/fbConfig";

//Initial Value of Slice
const initialState = {
  EmpManageJob: [
    {
      Id: "Primary-1",
      Title: "Orthoptist",
      Address: "Apollo Hospitals",
      Category: "ophthalmologist",
      JobType: "Fulltime",
      Applicants: "16 Applied",
      CreatedDate: "08/06/2023",
      ExpiredDate: "18/06/2023",
      Action: "",
      ImgUrl: "https://c2m-edutech.com/demo/photo6_124.jpg",
    },
    {
      Id: "Primary-2",
      Title: "Mental Health Services",
      Address: "Manipal Hospitals",
      Category: "Psychiatrists",
      JobType: "Fulltime",
      Applicants: "06 Applied",
      CreatedDate: "28/05/2023",
      ExpiredDate: "08/06/2023",
      Action: "",
      ImgUrl: "https://c2m-edutech.com/demo/photo5_124.jpg",
    },
  ],
  loading: false,
  error: null,
};

//Action of Slice
const EmpManageJobSlice = createSlice({
  name: "EmpManageJob",
  initialState,
  reducers: {
    setEmpManageJobStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setEmpManageJobSuccess: (state, action) => {
      state.loading = false;
      state.EmpManageJob = action.payload;
    },
    setEmpManageJobFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

//Export of Slice
export const {
  setEmpManageJobStart,
  setEmpManageJobSuccess,
  setEmpManageJobFailure,
} = EmpManageJobSlice.actions;
export default EmpManageJobSlice.reducer;

export const fetchEmpManageJob = (userUUID) => async (dispatch) => {
  try {
    dispatch(setEmpManageJobStart());
    const querySnapshot = await getDocs(
      collection(firestore, "Users", userUUID, "EmpManageJob")
    );
    const EmpManageJob = [];
    querySnapshot.forEach((doc) => {
      EmpManageJob.push(doc.data());
    });
    dispatch(setEmpManageJobSuccess(EmpManageJob));
  } catch (error) {
    dispatch(setEmpManageJobFailure(error.message));
  }
};
