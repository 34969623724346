import { createSlice } from "@reduxjs/toolkit";
import { getDocs, collection } from "firebase/firestore";
import { firestore } from "../../config/fbConfig";

//Initial Value of Slice
const initialState = {
  EmpBookmark: [
    {
      Id: "Primary-1",
      Title: "Suma",
      Address: "Avanta Healthcare Pvt Ltd, Bengaluru, Karnataka",
      Category: "Dentist",
      Duration: " in 20 days ",
      Date: "18/06/2023",
      Status: "Pending",
      ImgUrl: "https://c2m-edutech.com/demo/person4.jpg",
    },
    {
      Id: "Primary-2",
      Title: "Sanjay",
      Address: "Kasukurthi Healthcare Pvt Ltd, Bengaluru, Karnataka",
      Category: "Cardiologist",
      Duration: "in 10 days ",
      Date: "08/01/2024",
      Status: "Pending",
      ImgUrl: "https://c2m-edutech.com/demo/person3.jpg",
    },
  ],
  loading: false,
  error: null,
};

//Action of Slice
const EmpBookmarkSlice = createSlice({
  name: "EmpBookmark",
  initialState,
  reducers: {
    setEmpBookmarkStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setEmpBookmarkSuccess: (state, action) => {
      state.loading = false;
      state.EmpBookmark = action.payload;
    },
    setEmpBookmarkFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

//Export of Slice
export const {
  setEmpBookmarkStart,
  setEmpBookmarkSuccess,
  setEmpBookmarkFailure,
} = EmpBookmarkSlice.actions;
export default EmpBookmarkSlice.reducer;

export const fetchEmpBookmark = (userUUID) => async (dispatch) => {
  try {
    dispatch(setEmpBookmarkStart());
    const querySnapshot = await getDocs(
      collection(firestore, "Users", userUUID, "EmpBookmark")
    );
    const EmpBookmark = [];
    querySnapshot.forEach((doc) => {
      EmpBookmark.push(doc.data());
    });
    dispatch(setEmpBookmarkSuccess(EmpBookmark));
  } catch (error) {
    dispatch(setEmpBookmarkFailure(error.message));
  }
};
